import type { Dispatch, SetStateAction } from "react";
import { createContext, useContext } from "react";

export type PreventNavForm = {
	id: string;
	isDirty: boolean;
};

export const PreventNavContext = createContext<{
	forms: PreventNavForm[];
	addForm: (form: PreventNavForm) => void;
	updateForm: (id: string, isDirty: boolean) => void;
	getForm: (value: string, forms: PreventNavForm[]) => boolean;
	setForms: Dispatch<SetStateAction<PreventNavForm[]>>;
}>({
	forms: [],
	addForm: () => {},
	updateForm: () => {},
	getForm: () => false,
	setForms: () => {},
});

export function usePreventNav() {
	const ctx = useContext(PreventNavContext);
	if (ctx === undefined) {
		throw new Error("usePreventNav must be called inside a context provider");
	}
	return ctx;
}
