import clsx from "clsx";
import type { ComponentType, ReactNode } from "react";
import { createElement } from "react";
import { Link } from "react-router-dom";

import type { ButtonColor } from "./button";
import { useButtonStyle } from "./button";
import type { IconPropsWithoutChildren } from "./icon";

interface ButtonLinkProps {
	to: string;
	children: ReactNode;
	className?: string;
	title?: string;
	loading?: boolean;
	disabled?: boolean;
	color?: ButtonColor;
	notRounded?: boolean;
	icon?: ComponentType<IconPropsWithoutChildren>;
	target?: "_blank";
}

/**
 * Render a react router navigation link shaped like a button
 */
export function ButtonLink({
	children,
	className,
	loading = false,
	disabled = false,
	color = "info",
	notRounded,
	icon,
	...rest
}: ButtonLinkProps) {
	const buttonStyle = useButtonStyle({
		color,
		disabled,
		loading,
		notRounded,
		hasIcon: icon !== undefined,
	});

	return (
		<Link
			className={clsx(className, buttonStyle)}
			onClick={(e) => {
				if (disabled) {
					e.preventDefault();
				}
			}}
			{...rest}
		>
			{icon && createElement(icon)}
			{children}
		</Link>
	);
}

export function IconButtonLink({
	className,
	loading = false,
	disabled = false,
	color = "info",
	notRounded,
	...rest
}: ButtonLinkProps) {
	const buttonStyle = useButtonStyle({
		color,
		disabled,
		loading,
		isIcon: true,
		notRounded,
	});

	return (
		<Link
			className={clsx(className, buttonStyle)}
			onClick={(e) => {
				if (disabled) {
					e.preventDefault();
				}
			}}
			{...rest}
		/>
	);
}
