import { useEffect } from "react";

import { os } from "@utils/os";

interface Options {
	/* keydown will be used instead of keyup event, default false */
	keydown: boolean;
	/* defines the separator for keys combination, default "+" */
	separator: string;
}

/**
 * Define a comma separated list of hotkeys with either:
 * - a combination of keys (eg: "Alt+s")
 * - one key
 *
 * On MacOS, alt key is automatically replace by ctrl key.
 *
 * @example
 * useHotkeys('Alt+s,ArrowDown', useCallback((event, hotkey) => {
 *  if(hotkey === "ArrowDown") {
 *    // the arrow down key was pressed
 *  } else if (hotkey === "Alt+s") {
 *    // Alt (or ctrl on MacOs) and "s" keys were pressed
 *  }
 * }), []);
 */
export function useHotkey(
	hotkeysList: string,
	callback: (event: KeyboardEvent, hotkey: string) => void,
	options?: Partial<Options>,
) {
	const { keydown, separator } = {
		...{ keydown: false, separator: "+" },
		...options,
	};

	return useEffect(() => {
		const hotkeys = hotkeysList.split(",");
		const keysPressed = new Set<string>();
		const hotkeysMap = new Map<string, string[]>();
		for (const hotkey of hotkeys) {
			const parts = hotkey.split(separator);
			hotkeysMap.set(
				hotkey,
				parts.map((p) => (p === "Alt" && os() === "MacOS" ? "Control" : p)),
			);
		}

		const triggerHotkeys = (e: KeyboardEvent) => {
			const pressed = [...keysPressed];
			for (const [hotkey, parts] of hotkeysMap.entries()) {
				const hasCombination = pressed.every((key) => parts.includes(key)) && pressed.length === parts.length;
				if (hasCombination) callback(e, hotkey);
			}
		};

		const handleKeydown = (e: KeyboardEvent) => {
			keysPressed.add(e.key);
			if (keydown) triggerHotkeys(e);
		};

		const handleKeyup = (e: KeyboardEvent) => {
			if (!keydown) triggerHotkeys(e);
			keysPressed.delete(e.key);
		};

		window.addEventListener("keydown", handleKeydown);
		window.addEventListener("keyup", handleKeyup);

		return () => {
			window.removeEventListener("keydown", handleKeydown);
			window.removeEventListener("keyup", handleKeyup);
		};
	}, [callback, keydown, hotkeysList, separator]);
}
