import { WatchQueryFetchPolicy } from "@apollo/client";
import { useDefaultBrand, useDefaultLocale } from "../pages/shared/preferences";

type ArgsWithVariables<V> = {
	variables: V;
} & { fetchPolicy?: WatchQueryFetchPolicy; skip?: boolean };
/**  brandId and includeBrandData are optional */
type QueryVariables<T extends (args: ArgsWithVariables<Parameters<T>[0]["variables"]>) => ReturnType<T>> =
	ArgsWithVariables<Omit<Parameters<T>[0]["variables"], "brandId" | "includeBrandData">>;

/**
 * This is a higher-order hook that enhances the provided hook by injecting global variables into its arguments.
 * Specifically, it adds `brandId` and `includeBrandData` into the arguments of the original hook.
 * Note: The added variables `brandId` and `includeBrandData` are optional. If they are not provided, suitable defaults will be used.
 */

const useInjectGlobalVariables = <T extends (args: ArgsWithVariables<Parameters<T>[0]["variables"]>) => ReturnType<T>>(
	hook: T,
	args: QueryVariables<(args: ArgsWithVariables<Parameters<T>[0]["variables"]>) => ReturnType<T>>,
): ReturnType<T> => {
	const defaultBrand = useDefaultBrand();
	const defaultLocale = useDefaultLocale();

	return hook({
		fetchPolicy: "cache-and-network",
		...(args ?? {}),
		variables: {
			brandId: defaultBrand?.id ?? "",
			brandIds: defaultBrand?.id ? [defaultBrand?.id] : undefined,
			locales: defaultLocale?.id ? [defaultLocale?.id] : undefined,
			includeBrandData: Boolean(defaultBrand?.id),
			includeLocalesData: Boolean(defaultLocale?.id),
			...(args.variables ?? {}),
		},
	});
};

export default useInjectGlobalVariables;
