import type { IconPropsWithoutChildren } from "@components/icon";
import { Icon } from "@components/icon";

export function MenuIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
			</svg>
		</Icon>
	);
}
