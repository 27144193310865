import { Badge } from "../../../../components/badge";
import { StatusLight } from "../../../../components/status-light";
import { BrandOptionFragment, PersonSearchEntityFragment } from "../../../../graphql/types";
import { ManIcon } from "../../../../icons/man-icon";

interface PersonListItemProps {
	person: PersonSearchEntityFragment;
	brand: BrandOptionFragment | undefined;
}

const PersonListItem = ({ person, brand }: PersonListItemProps) => {
	const { ref, countries, activeOnBrands, poster, firstName, lastName } = person;
	const countriesNames = countries.map(({ name }) => name);
	const isActiveOnBrand = activeOnBrands?.some((element) => element.ref === brand?.ref);

	return (
		<div className="flex gap-3">
			<span className="basis-16 shrink-0">
				<img
					alt="Poster"
					className="rounded aspect-poster w-16"
					src={poster ? `${__ENV__.IMAGE_CDN_URL}/c_74_100/${poster.path}` : "/images/default-image.png"}
				/>
			</span>
			<div className="flex flex-col gap-1">
				<div className="font-semibold items-baseline flex gap-2 text-wrap">
					<span>
						<ManIcon className="shrink-0" size="S" />
					</span>
					{brand !== undefined && (
						<span>
							<StatusLight variant={isActiveOnBrand ? "positive" : "negative"} />
						</span>
					)}
					<span>
						{firstName} {lastName} <span className="text-xs">({ref})</span>
					</span>
				</div>

				{countriesNames.length > 0 && (
					<div className="flex flex-wrap gray-500 gap-1">
						{countriesNames.map((country) => (
							<Badge key={country}>{country}</Badge>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default PersonListItem;
