import type { ReactNode } from "react";
import { Suspense, lazy, useState } from "react";
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { Loader } from "@components/loader";
import { useSecurity } from "@components/security";
import { RequireAuth } from "@utils/auth";
import { readFromStorage, writeToStorage } from "@utils/storage";

import { PermissionTarget } from "../../config/permissionTargets";
import { NotFound } from "../errors/not-found";
import Home from "../home";
import Login from "../login";
import { PreventNavigation } from "../shared/components/prevent-navigation";
import type { PreventNavForm } from "./prevent-navigation-ctx";
import { PreventNavContext } from "./prevent-navigation-ctx";
import { Shell } from "./shell";

// biome-ignore lint/suspicious/noExplicitAny: lazy loaded modules are variadic
function refreshOnFailure(callback: () => Promise<any>) {
	return () =>
		callback().catch((err) => {
			const hasFailed = readFromStorage<boolean>("chunk_load_error") ?? false;
			if (hasFailed) {
				// Reloading did not help.
				throw err;
			}
			// Keep track of the failure so the refresh does not end up looping.
			// The storage will be cleared after the refresh if a newer version
			// of the app is available.
			writeToStorage("chunk_load_error", true);

			// Force the browser to refresh the page, hoping to clear the cache.
			location.reload();

			// Return a mock ES module so the UI is not broken.
			return {
				default: Loader,
			};
		});
}

const AuthorRoutes = lazy(refreshOnFailure(() => import("../authors")));
const BoxofficesRoutes = lazy(refreshOnFailure(() => import("../boxoffices")));
const CarouselRoutes = lazy(refreshOnFailure(() => import("../carousels")));
const ClockRoutes = lazy(refreshOnFailure(() => import("../clocks")));
const CompanyRoutes = lazy(refreshOnFailure(() => import("../companies")));
const CountryRoutes = lazy(refreshOnFailure(() => import("../countries")));
const DuplicateRoutes = lazy(refreshOnFailure(() => import("../duplicates")));
const GraphRoutes = lazy(refreshOnFailure(() => import("../graph")));
const ImportRoutes = lazy(refreshOnFailure(() => import("../imports")));
const MatchingToolRoutes = lazy(refreshOnFailure(() => import("../matching_tool")));
const MovieRoutes = lazy(refreshOnFailure(() => import("../movies")));
const FestivalsRoutes = lazy(refreshOnFailure(() => import("../festivals")));
const NewsRoutes = lazy(refreshOnFailure(() => import("../news")));
const PersonRoutes = lazy(refreshOnFailure(() => import("../persons")));
const ImageRoutes = lazy(refreshOnFailure(() => import("../images")));
const ReviewsRoutes = lazy(refreshOnFailure(() => import("../reviews")));
const ProxyRoutes = lazy(refreshOnFailure(() => import("../proxy")));
const SecurityRoutes = lazy(refreshOnFailure(() => import("../security")));
const SeriesRoutes = lazy(refreshOnFailure(() => import("../series")));
const SocialRoutes = lazy(refreshOnFailure(() => import("../social")));
const SpecialOperationRoutes = lazy(refreshOnFailure(() => import("../special-operation")));
const TagRoutes = lazy(refreshOnFailure(() => import("../tags")));
const TestRoutes = lazy(refreshOnFailure(() => import("../test")));
const TheaterRoutes = lazy(refreshOnFailure(() => import("../theaters")));
const JumpPlatformRoutes = lazy(refreshOnFailure(() => import("../jumpPlatform")));
const TriviaRoutes = lazy(refreshOnFailure(() => import("../trivias")));
const VideoRoutes = lazy(refreshOnFailure(() => import("../videos")));
const WysiwygPage = lazy(refreshOnFailure(() => import("../wysiwyg")));
const IconsPage = lazy(refreshOnFailure(() => import("../icons")));

const PreventNavWrapper = ({ children }: { children: ReactNode }) => {
	const [forms, setForms] = useState<PreventNavForm[]>([]);
	return (
		<PreventNavContext.Provider
			value={{
				forms,
				addForm: (value: PreventNavForm) => setForms((forms) => [...forms, value]),
				updateForm: (id: string, isDirty: boolean) => {
					const _forms = [...forms];
					const form = _forms.find((element) => element.id === id);

					if (form) {
						form.isDirty = isDirty;
					}

					setForms(_forms);
				},
				getForm: (id) => forms.some((element) => element.id === id),
				setForms,
			}}
		>
			<PreventNavigation />
			{children}
		</PreventNavContext.Provider>
	);
};

export function App() {
	const { isGranted, isGrantedNew } = useSecurity();

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route path="/login" element={<Login />} />
				<Route
					path="/"
					element={
						<RequireAuth loginPath="/login">
							<Shell />
						</RequireAuth>
					}
				>
					<Route index element={<Home />} />
					<Route path="companies/*" element={<CompanyRoutes />} />
					<Route
						path="movies/*"
						element={
							<PreventNavWrapper>
								<MovieRoutes />
							</PreventNavWrapper>
						}
					/>
					<Route path="images/*" element={<ImageRoutes />} />
					<Route path="news/*" element={<NewsRoutes />} />
					<Route path="persons/*" element={<PersonRoutes />} />
					<Route path="series/*" element={<SeriesRoutes />} />
					<Route
						path="theaters/*"
						element={
							<PreventNavWrapper>
								<TheaterRoutes />
							</PreventNavWrapper>
						}
					/>
					{isGrantedNew(PermissionTarget.FESTIVAL) && <Route path="festivals/*" element={<FestivalsRoutes />} />}
					<Route path="authors/*" element={<AuthorRoutes />} />
					{isGranted("boxoffice.*") && <Route path="boxoffices/*" element={<BoxofficesRoutes />} />}
					{isGranted("carousel.*") && <Route path="carousels/*" element={<CarouselRoutes />} />}
					{isGranted("duplicate.*") && <Route path="duplicates/*" element={<DuplicateRoutes />} />}
					{isGranted("showtimes.*") && <Route path="clocks/*" element={<ClockRoutes />} />}
					{isGranted("country.*") && <Route path="countries/*" element={<CountryRoutes />} />}
					<Route path="matching-tool/*" element={<MatchingToolRoutes />} />
					{isGranted("graph.*") && <Route path="graph/*" element={<GraphRoutes />} />}
					{isGranted("graph.*") && <Route path="proxy/*" element={<ProxyRoutes />} />}
					<Route path="imports/*" element={<ImportRoutes />} />
					{isGranted("security.*") && <Route path="security/*" element={<SecurityRoutes />} />}
					{isGranted("social.*") && <Route path="social/*" element={<SocialRoutes />} />}
					{isGranted("tag.*") && <Route path="tags/*" element={<TagRoutes />} />}
					{isGrantedNew(PermissionTarget.BRAND_TRIVIA) && <Route path="trivias/*" element={<TriviaRoutes />} />}
					{isGrantedNew(PermissionTarget.MEDIA_VIDEO) && <Route path="videos/*" element={<VideoRoutes />} />}
					{isGranted("specialOperation.*") && <Route path="special-operations/*" element={<SpecialOperationRoutes />} />}
					<Route path="reviews/*" element={<ReviewsRoutes />} />
					<Route path="jump-platform/*" element={<JumpPlatformRoutes />} />
					{isGranted("admin.*") && <Route path="wysiwyg/*" element={<WysiwygPage />} />}
					<Route path="test/*" element={<TestRoutes />} />
					<Route path="icons/*" element={<IconsPage />} />
					<Route path="*" element={<NotFound />} />
				</Route>
			</>,
		),
	);

	return (
		<Suspense fallback={<Loader />}>
			<RouterProvider router={router} />
		</Suspense>
	);
}
