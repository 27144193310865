import { ChangeEvent, ReactNode, useCallback, useMemo, useRef, useState } from "react";

import { useSearchEntityQuery } from "@graphql/apollo";
import {
	CompanySearchEntityFragment,
	EntityType,
	MovieSearchEntityFragment,
	PersonSearchEntityFragment,
	SeriesSearchEntityFragment,
	TheaterSearchEntityFragment,
} from "@graphql/types";
import { useDebounce } from "@hooks/use-debounce";

import { Input } from "../../../components/input";
import { useSecurity } from "../../../components/security";
import { Link } from "../../../components/typography";
import { SearchIcon } from "../../../icons/search-icon";
import { useDefaultBrand } from "../preferences";

import clsx from "clsx";
import { useIntl } from "react-intl";
import { Button } from "../../../components/button";
import { ProgressBar } from "../../../components/progress-bar";
import { useHotkey } from "../../../hooks/use-hotkey";
import useInjectGlobalVariables from "../../../hooks/use-inject-global-variables";
import { ClearIcon } from "../../../icons/clear-icon";
import { getGlobalEntityLinkAttrs } from "../../../utils/global-routes";
import CompanyListItem from "./entity-list-item/company-list-item";
import MovieListItem from "./entity-list-item/movie-list-item";
import PersonListItem from "./entity-list-item/person-list-item";
import SeriesListItem from "./entity-list-item/series-list-item";
import TheaterListItem from "./entity-list-item/theater-list-item";

const PERMISSIONS = [
	["movie.*", EntityType.Movie],
	["series.*", EntityType.Series],
	["companies.*", EntityType.Company],
	["persons.*", EntityType.Person],
];

export type SearchEntity =
	| MovieSearchEntityFragment
	| SeriesSearchEntityFragment
	| CompanySearchEntityFragment
	| PersonSearchEntityFragment
	| TheaterSearchEntityFragment;

type SearchEntityByIndex = {
	Movie: MovieSearchEntityFragment[];
	Person: PersonSearchEntityFragment[];
	Series: SeriesSearchEntityFragment[];
	Company: CompanySearchEntityFragment[];
	Theater: TheaterSearchEntityFragment[];
};

type PossibleSearchEntitiesTypes = keyof SearchEntityByIndex;

export type ListItemProps = {
	entity: SearchEntity;
};
export const ListItem = ({ entity }: ListItemProps) => {
	const defaultBrand = useDefaultBrand();
	const { __typename } = entity;

	const className = "hover:no-underline";
	let listItem: ReactNode;
	if (__typename === "Movie") listItem = <MovieListItem movie={entity} brand={defaultBrand} />;
	else if (__typename === "Series") listItem = <SeriesListItem series={entity} brand={defaultBrand} />;
	else if (__typename === "Person") listItem = <PersonListItem person={entity} brand={defaultBrand} />;
	else if (__typename === "Company") listItem = <CompanyListItem company={entity} brand={defaultBrand} />;
	else if (__typename === "Theater") listItem = <TheaterListItem theater={entity} brand={defaultBrand} />;

	if (!listItem) return null;
	return (
		<Link className={className} to={getGlobalEntityLinkAttrs(entity).to}>
			{listItem}
		</Link>
	);
};

type ListProps = {
	index: number;
	title: string;
	entities: SearchEntity[];
	onItemClick: () => void;
};
const List = ({ index, title, entities, onItemClick }: ListProps) => (
	<>
		<h2
			className={clsx("font-bold uppercase mx-4  pb-2 border-b border-gray-200", {
				"pt-2": index > 0,
			})}
		>
			{title}
		</h2>
		<ul className="grid grid-cols-3 py-1 gap-2 mt-4 p-4">
			{entities.map((entity, ix) => (
				<li
					key={`${entity.__typename}-${ix}`}
					className="overflow-hidden hover:bg-gray-200 hover:rounded hover:overflow-visible"
					onClick={onItemClick}
				>
					<ListItem entity={entity} />
				</li>
			))}
		</ul>
	</>
);

const GlobalSearchInput = () => {
	const { formatNumber } = useIntl();
	const { isGranted } = useSecurity();
	const [show, setShow] = useState(false);
	const [search, setSearch] = useState("");
	const debouncedSearch = useDebounce(search);
	const inputRef = useRef<HTMLInputElement | null>(null);

	useHotkey(
		"Escape,Control+Shift+F",
		useCallback((_ev, hotkey) => {
			if (hotkey === "Escape") handleClose();
			if (hotkey === "Control+Shift+F") handleOpen();
		}, []),
	);

	const availableEntities = useMemo(
		() => PERMISSIONS.flatMap(([permission, entityType]) => (isGranted(permission) ? (entityType as EntityType) : [])),
		[isGranted],
	);

	const { data, previousData, loading } = useInjectGlobalVariables(useSearchEntityQuery, {
		skip: !show,
		variables: {
			search: debouncedSearch || null,
			limit: 9,
			offset: 0,
			entityType: availableEntities,
		},
	});

	const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
		if (event.target.value) setShow(true);
	};

	const toggleScrolling = (enable: boolean) => {
		if (enable) document.body.style.overflow = "hidden";
		else document.body.style.overflow = "auto";
	};

	const handleOpen = () => {
		setShow(true);
		if (!show) inputRef.current?.setSelectionRange(-1, -1);
		inputRef.current?.focus(); // set focus + end of text position
		toggleScrolling(true);
	};

	const handleClose = () => {
		setShow(false);
		toggleScrolling(false);
	};

	const dataTarget = data ?? previousData;
	const count = dataTarget?.searchEntities.totalCount ?? 0;
	const searchEntities = dataTarget?.searchEntities?.nodes as SearchEntity[];

	const entitiesByIndex = searchEntities
		? Object.entries(
				searchEntities?.reduce(
					(acc, entity) => {
						if (!(entity.__typename in acc)) acc[entity.__typename] = [];
						(acc[entity.__typename] as SearchEntity[]).push(entity);
						return acc;
					},
					{
						Movie: [],
						Person: [],
						Series: [],
						Company: [],
						Theater: [],
					} as SearchEntityByIndex,
				),
		  ).reduce(
				(a, [k, items]) => {
					if (items.length > 0) a[k as PossibleSearchEntitiesTypes] = items;
					return a;
				},
				{} as Record<PossibleSearchEntitiesTypes, SearchEntity[]>,
		  )
		: undefined;

	return (
		<>
			<div
				className={clsx("z-30", {
					"fixed top-0 left-0 w-[100vw] h-screen overflow-y-auto overflow-x-hidden": show,
				})}
			>
				<div className="flex flex-col min-h-full">
					{/* Search bar */}
					<div
						className={clsx("h-[55px] z-20", {
							"sticky top-0 border-b border-gray-200 bg-white": show,
						})}
					>
						<div
							className={clsx("h-full gap-4", {
								"px-2": !show,
								"w-full px-4 flex max-w-[1200px] mx-auto border-r border-l border-gray-200": show,
							})}
						>
							<div className={clsx({ hidden: !show, "flex pr-4 items-center border-r border-gray-200": show })}>
								<Button className="hover:bg-transparent pr-0" color="transparent" onClick={handleClose}>
									<img alt="Boxoffice Logo" src="/images/boxoffice-logo.svg" width="160" />
								</Button>
							</div>
							<div className={clsx("h-full flex flex-grow items-center gap-2")}>
								<Input
									ref={inputRef}
									onFocus={() => inputRef.current?.select()}
									className={clsx("h-full w-full border-none pl-3", { "text-lg": show })}
									value={search}
									onChange={handleSearchChange}
									onClick={handleOpen}
									placeholder="Search across all entities..."
								/>
								{!show && (
									<Button className="hover:bg-transparent pr-0" color="transparent" onClick={handleOpen}>
										<SearchIcon size="M" />
									</Button>
								)}
								{show && (
									<Button className="hover:bg-transparent pr-0" color="transparent" onClick={handleClose}>
										<ClearIcon size="L" />
									</Button>
								)}
							</div>
						</div>
					</div>
					{/* Dropdown */}
					<div
						className={clsx({
							"w-full backdrop-blur-md drop-shadow-xl flex-1 min-h-[30%] z-10": show,
						})}
						onClick={handleClose}
					>
						<div
							className={clsx({
								"max-w-[1200px] mx-auto bg-white border-gray-200 border-l border-r rounded-b-md": show,
								"pb-16": count > 0,
							})}
							onClick={(e) => {
								// prevent clicking on content from hiding the whole component
								e.stopPropagation();
							}}
						>
							<ProgressBar
								isIndeterminate={loading}
								color="info"
								className={clsx({
									"opacity-0": !loading,
								})}
							/>

							{show && (
								<>
									{entitiesByIndex && (
										<div>
											<h2 className="font-bold mx-4 pb-2 w-full text-center ">
												{formatNumber(count)}
												{count > 10000 ? "+" : ""} result{count > 1 ? "s" : ""} across all entities (Movies, Persons, Series or
												Companies)...
											</h2>
											{Object.entries(entitiesByIndex).map(([typename, entities], ix) => (
												<List
													index={ix}
													key={`${typename}-${ix}`}
													title={`${typename} (top ${entities.length})`}
													entities={entities}
													onItemClick={handleClose}
												/>
											))}
										</div>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default GlobalSearchInput;
