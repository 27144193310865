import type { IllustrationPropsWithoutChildren } from "@components/illustration";
import { Illustration } from "@components/illustration";

export function Unavailable(props: IllustrationPropsWithoutChildren) {
	return (
		<Illustration {...props}>
			<svg width="143.1" height="94">
				<g
					transform="translate(-416.2 -3367.5)"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					fill="none"
				>
					<circle cx="18.1" cy="18.1" r="18.1" transform="translate(417.7 3396.9)" strokeWidth="3" />
					<path
						strokeWidth="3"
						d="M423.4 3402.2l24.5 24.4M435.8 3390v-19a2.006 2.006 0 012-2h118a2.006 2.006 0 012 2v23a2.006 2.006 0 01-2 2h-104M457.8 3401h98a2.006 2.006 0 012 2v23a2.006 2.006 0 01-2 2h-97M452.8 3433h103a2.006 2.006 0 012 2v23a2.006 2.006 0 01-2 2h-118a2.006 2.006 0 01-2-2v-18M546.8 3429v3M546.8 3397v3"
					/>
					<circle cx="4" cy="4" r="4" transform="translate(542.8 3378.5)" strokeWidth="2" />
					<circle cx="4" cy="4" r="4" transform="translate(542.8 3411)" strokeWidth="2" />
					<circle cx="4" cy="4" r="4" transform="translate(542.8 3442.5)" strokeWidth="2" />
				</g>
			</svg>
		</Illustration>
	);
}
