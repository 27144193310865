import * as Sentry from "@sentry/react";
import { clearStorage, readFromStorage, writeToStorage } from "@utils/storage";
import { createRoot } from "react-dom/client";
import { Main } from "./main";
import { booleanFromString } from "./utils/boolean";

if (__ENV__.SENTRY_ENABLED) {
	const release = __ENV__.SENTRY_RELEASE ?? undefined;
	Sentry.addTracingExtensions();
	Sentry.init({
		...(release ? { release } : {}),
		enabled: __ENV__.SENTRY_ENABLED,
		dsn: __ENV__.SENTRY_DSN,
		tracesSampleRate: __ENV__.SENTRY_TRACING_RATE,
		environment: __ENV__.SENTRY_ENV,
	});
}

// Increment this if you introduce a breaking changes to the config (replaced by the CI for each deployment)
const APP_VERSION = __ENV__.APP_VERSION ?? "3";
const version = readFromStorage("app:version");
const storageClearedOnLastVersion = readFromStorage("app:storage:latest_version");

const needVersionReload = version !== APP_VERSION;
const needClearStorage =
	(booleanFromString(__ENV__.APP_DEPLOY_CLEAR_STORAGE) ?? false) && storageClearedOnLastVersion !== APP_VERSION;

if (needClearStorage) {
	// biome-ignore lint/suspicious/noConsoleLog: We need a log in this case
	console.log("app:storage cleared");
	clearStorage();
	writeToStorage("app:storage:latest_version", APP_VERSION);
}

if (needVersionReload) {
	writeToStorage("app:version", APP_VERSION);
	// biome-ignore lint/suspicious/noConsoleLog: We need a log in this case
	console.log("app:version reloaded");
	location.reload();
}

// biome-ignore lint/style/noNonNullAssertion: Can't create root withtout non null assertion
const container = document.querySelector("[data-root]")!;
const root = createRoot(container);

root.render(<Main />);
