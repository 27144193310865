import * as Sentry from "@sentry/react";
import type { ComponentType, ReactElement, ReactNode } from "react";
import { Component, isValidElement } from "react";

import { ErrorMessage } from "./error-message";

interface FallbackProps {
	error: Error;
	resetError(): void;
}

type ErrorBoundaryProps =
	| ErrorBoundaryPropsWithComponent
	| ErrorBoundaryPropsWithFallback
	| ErrorBoundaryPropsWithRender;

interface ErrorBoundaryPropsWithComponent {
	children: ReactNode;
	fallback?: never;
	fallbackComponent: ComponentType<FallbackProps>;
	fallbackRender?: never;
}

interface ErrorBoundaryPropsWithFallback {
	children: ReactNode;
	fallback: ReactElement;
	fallbackComponent?: never;
	fallbackRender?: never;
}

interface ErrorBoundaryPropsWithRender {
	children: ReactNode;
	fallback?: never;
	fallbackComponent?: never;
	fallbackRender?: (props: FallbackProps) => ReactNode;
}

interface ErrorBoundaryState {
	error: Error | null;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	state = {
		error: null,
	};

	componentDidCatch(error: Error): void {
		if (__ENV__.SENTRY_ENABLED) Sentry.captureException(error);
		this.setState({
			error,
		});
	}

	render(): ReactNode {
		const { children, fallback, fallbackComponent: Fallback, fallbackRender = renderError } = this.props;
		const { error } = this.state;
		if (!error) return children;

		if (isValidElement(fallback)) return fallback;

		const props = {
			error,
			resetError: () => {
				this.setState({
					error: null,
				});
			},
		};
		if (Fallback) return <Fallback {...props} />;
		return fallbackRender(props);
	}
}

const renderError = ({ error }: FallbackProps) => <ErrorMessage error={error} />;
