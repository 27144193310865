import clsx from "clsx";
import type { HTMLAttributes } from "react";

export type BadgeColor = "neutral" | "success" | "info" | "warning" | "error";

interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
	color?: BadgeColor;
}

export function Badge({ color = "neutral", className, children, ...rest }: BadgeProps) {
	return (
		<span
			className={clsx(
				"inline-flex items-center rounded-full text-xs font-medium leading-5 px-2 whitespace-nowrap",
				{
					"text-gray-700 bg-gray-100": color === "neutral",
					"text-emerald-700 bg-emerald-100": color === "success",
					"text-blue-700 bg-blue-100": color === "info",
					"text-amber-700 bg-amber-100": color === "warning",
					"text-red-700 bg-red-100": color === "error",
				},
				className,
			)}
			{...rest}
		>
			{children}
		</span>
	);
}
