import { useGetFestivalEditionMetadataQuery } from "@graphql/apollo";

import { getGlobalEntityTitleAttrs } from "../../../utils/global-titles";
import { useFestivalLocale } from "./festival";

export const useFestivalEditionEntity = ({
	festivalRef,
	festivalEditionRef,
}: {
	festivalRef: string;
	festivalEditionRef: string;
}) => {
	const locale = useFestivalLocale();
	if (!festivalRef) throw new Error("useFestivalEditionEntity: mising ref parameter");

	const { data, loading } = useGetFestivalEditionMetadataQuery({
		skip: !(locale && festivalRef),
		fetchPolicy: "cache-and-network",
		variables: {
			ref: festivalEditionRef,
			locale: locale.id,
		},
	});

	const entity = data?.entityByRef;
	const isCorrectEntity = entity?.__typename === "FestivalEdition";

	const titleAttrs = isCorrectEntity ? getGlobalEntityTitleAttrs(entity) : undefined;
	return {
		loading,
		festivalEdition: isCorrectEntity ? entity : undefined,
		titleAttrs,
	};
};
