import clsx from "clsx";
import type { HTMLAttributes } from "react";

type BackdropProps = HTMLAttributes<HTMLDivElement>;

export function Backdrop(props: BackdropProps) {
	const { children, className, ...rest } = props;
	return (
		<div
			className={clsx(
				className,
				"fixed inset-0 z-50 pt-10 px-6 pb-6 bg-black/75 shadow-lg overflow-hidden outline-0 cursor-pointer",
			)}
			onClick={(e) => e.stopPropagation()}
			{...rest}
		>
			{children}
		</div>
	);
}
