import { useGetFestivalCompetitionMetadataQuery } from "@graphql/apollo";

import { getGlobalEntityTitleAttrs } from "../../../utils/global-titles";
import { FESTIVAL_OUT_OF_COMPETITION_ID, FESTIVAL_OUT_OF_COMPETITION_VIRTUAL_FG } from "../constant";
import { useFestivalLocale } from "./festival";

export const useFestivalCompetitionEntity = ({
	competitionRef,
}: {
	competitionRef: string;
}) => {
	const locale = useFestivalLocale();
	if (!competitionRef) throw new Error("useFestivalCompetitionEntity: mising ref parameter");

	const isOutOfCompetition = competitionRef === FESTIVAL_OUT_OF_COMPETITION_ID;
	const { data, loading } = useGetFestivalCompetitionMetadataQuery({
		skip: !(locale && competitionRef) || isOutOfCompetition,
		fetchPolicy: "cache-and-network",
		variables: {
			ref: competitionRef,
			locale: locale.id,
		},
	});

	const entity = data?.entityByRef;
	const isCorrectEntity = entity?.__typename === "FestivalCompetition";

	const titleAttrs = isCorrectEntity ? getGlobalEntityTitleAttrs(entity) : undefined;
	return {
		loading,
		festivalCompetition: isCorrectEntity ? entity : undefined,
		titleAttrs: isOutOfCompetition ? { label: FESTIVAL_OUT_OF_COMPETITION_VIRTUAL_FG.name } : titleAttrs,
	};
};
