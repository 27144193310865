import type { IconPropsWithoutChildren } from "@components/icon";
import { Icon } from "@components/icon";

type WarningIconProps = IconPropsWithoutChildren & {
	title?: string;
};

export function WarningIcon({ title, ...props }: WarningIconProps) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				{title && <title>{title}</title>}
				<path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
			</svg>
		</Icon>
	);
}
