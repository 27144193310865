import clsx from "clsx";

export interface ProgressBarProps {
	className?: string;
	isIndeterminate?: boolean;
	min?: number;
	max?: number;
	value?: number;
	color?: "info" | "error" | "success" | "warning";
}

export function ProgressBar(props: ProgressBarProps) {
	const { className, value = 0, min = 0, max = 100, isIndeterminate = false, color = "info" } = props;

	return (
		<div className={clsx(className, "overflow-hidden h-1 bg-gray-200")}>
			<div
				className={clsx("h-full", {
					"animate-indeterminate": isIndeterminate,
					"bg-blue-400": color === "info",
					"bg-red-400": color === "error",
					"bg-green-400": color === "success",
					"bg-orange-400": color === "warning",
				})}
				style={
					isIndeterminate
						? undefined
						: {
								transition: "width 1s ease",
								width: `${((value - min) / (max - min)) * 100}%`,
						  }
				}
			/>
		</div>
	);
}
