/**
 * Common alignment stuff made for Grid and Flex components
 */

export interface AlignmentProps {
	alignContent?: "center" | "start" | "end" | "between" | "around" | "evenly";
	justify?: "start" | "end" | "center" | "between" | "around" | "evenly";
	justifyItems?: "auto" | "start" | "end" | "center" | "stretch";
	alignItems?: "start" | "end" | "center" | "baseline" | "stretch";
}

export function getAlignmentClasses({ alignContent, justify, justifyItems, alignItems }: AlignmentProps) {
	return [
		{
			"justify-start": justify === "start",
			"justify-end": justify === "end",
			"justify-center": justify === "center",
			"justify-between": justify === "between",
			"justify-around": justify === "around",
			"justify-evenly": justify === "evenly",
		},
		{
			"justify-items-auto": justifyItems === "auto",
			"justify-items-start": justifyItems === "start",
			"justify-items-end": justifyItems === "end",
			"justify-items-center": justifyItems === "center",
			"justify-items-stretch": justifyItems === "stretch",
		},
		{
			"content-center": alignContent === "center",
			"content-start": alignContent === "start",
			"content-end": alignContent === "end",
			"content-between": alignContent === "between",
			"content-around": alignContent === "around",
			"content-evenly": alignContent === "evenly",
		},
		{
			"items-start": alignItems === "start",
			"items-end": alignItems === "end",
			"items-center": alignItems === "center",
			"items-baseline": alignItems === "baseline",
			"items-stretch": alignItems === "stretch",
		},
	];
}

export interface ItemAlignmentProps {
	justifySelf?: "auto" | "start" | "end" | "center" | "stretch";
	alignSelf?: "auto" | "start" | "end" | "center" | "stretch";
}

export function getItemAlignmentClasses({ alignSelf, justifySelf }: ItemAlignmentProps) {
	return [
		{
			"self-auto": alignSelf === "auto",
			"self-start": alignSelf === "start",
			"self-end": alignSelf === "end",
			"self-center": alignSelf === "center",
			"self-stretch": alignSelf === "stretch",
		},
		{
			"justify-self-auto": justifySelf === "auto",
			"justify-self-start": justifySelf === "start",
			"justify-self-end": justifySelf === "end",
			"justify-self-center": justifySelf === "center",
			"justify-self-stretch": justifySelf === "stretch",
		},
	];
}
