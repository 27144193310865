import * as Apollo from "@apollo/client";
import type { OperationVariables } from "@apollo/client";

declare module "@apollo/client" {
	interface QueryHookOptions {
		throw?: boolean;
	}
}

// biome-ignore lint/suspicious/noExplicitAny: difficult to type here
export function useQuery<TData = any, TVariables extends OperationVariables = OperationVariables>(
	query: Apollo.DocumentNode,
	options?: Apollo.QueryHookOptions<Apollo.NoInfer<TData>, Apollo.NoInfer<TVariables>> & { throw?: boolean },
): Apollo.QueryResult<TData, TVariables> {
	const _throw = options?.throw ?? true;

	const result = Apollo.useQuery(query, options);

	if (_throw && result.error) throw result.error;

	return result;
}

export { useLazyQuery, useMutation } from "@apollo/client";
export type {
	LazyQueryHookOptions,
	MutationHookOptions,
	QueryHookOptions,
} from "@apollo/client";
