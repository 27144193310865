import clsx from "clsx";
import type { ReactElement } from "react";
import { NavLink } from "react-router-dom";

import { SecurityMode, useSecurity } from "@components/security";
import { BankIcon } from "@icons/bank-icon";
import { BoxofficeIcon } from "@icons/boxoffice-icon";
import { CarouselIcon } from "@icons/carousel-icon";
import { CountriesIcon } from "@icons/countries-icon";
import { FestivalIcon } from "@icons/festival-icon";
import { FilmStripIcon } from "@icons/filmstrip-icon";
import { GraphIcon } from "@icons/graph-icon";
import { ImportExportIcon } from "@icons/import-export-icon";
import { LocalActivityIcon } from "@icons/local-activity-icon";
import { ManIcon } from "@icons/man-icon";
import { MatchingIcon } from "@icons/matching-icon";
import { MovieIcon } from "@icons/movie-icon";
import { NewspaperVariantOutlineIcon } from "@icons/newspaper-variant-outline-icon";
import { OpeSpeIcon } from "@icons/ope-spe-icon";
import { PhotoLibraryIcon } from "@icons/photo-library-icon";
import { ProxyIcon } from "@icons/proxy-icon";
import { ReviewsIcon } from "@icons/reviews-icon";
import { RuleIcon } from "@icons/rule-icon";
import { ScreenIcon } from "@icons/screen-icon";
import { TagsIcon } from "@icons/tags-icon";
import { TriviaIcon } from "@icons/trivia-icon";
import { UserIcon } from "@icons/user-icon";
import { VideoLibraryIcon } from "@icons/video-library-icon";
import { PermissionTarget } from "../../config/permissionTargets";
import { LinkIcon } from "../../icons/link-icon";

interface MenuProps {
	showMenu: boolean;
}

interface SubNavProps {
	items?: NavItem[];
}

interface MenuLinkProps {
	link?: string;
	icon?: ReactElement;
	label: string;
	subNavs?: NavItem[];
}

interface NavItem {
	icon?: ReactElement;
	label: string;
	link?: string;
	permission?: string;
	subNavs?: NavItem[];
	securityMode?: SecurityMode;
}

const NAV_ITEMS: NavItem[] = [
	{
		label: "Entities",
		subNavs: [
			{
				icon: <MovieIcon />,
				label: "Movies",
				link: "/movies/",
			},
			{
				icon: <ScreenIcon />,
				label: "Series",
				link: "/series/",
			},
			{
				icon: <ManIcon />,
				label: "Persons",
				link: "/persons/",
			},
			{
				icon: <BankIcon />,
				label: "Companies",
				link: "/companies/",
			},
			{
				icon: <FilmStripIcon />,
				label: "Theaters",
				link: "/theaters/",
			},
			{
				icon: <FestivalIcon />,
				label: "Festivals",
				link: "/festivals/",
				permission: PermissionTarget.FESTIVAL,
				securityMode: SecurityMode.NEW,
			},
		],
	},
	{
		label: "Editorial",
		subNavs: [
			{
				icon: <NewspaperVariantOutlineIcon />,
				label: "News",
				link: "/news/",
			},
			{
				icon: <CarouselIcon />,
				label: "Carousels",
				permission: "carousel.*",
				link: "/carousels",
				subNavs: [
					{
						label: "Slides",
						link: "/carousels/slides",
					},
				],
			},
			{
				icon: <OpeSpeIcon />,
				label: "Special operations",
				link: "/special-operations/",
				permission: "specialOperation.*",
			},
			{
				icon: <TagsIcon />,
				label: "Tags",
				link: "/tags/",
				permission: "tag.*",
			},
			{
				icon: <TriviaIcon />,
				label: "Trivia",
				link: "/trivias/",
				permission: PermissionTarget.BRAND_TRIVIA,
				securityMode: SecurityMode.NEW,
			},
			{
				icon: <ReviewsIcon />,
				label: "Reviews",
				subNavs: [
					{
						label: "Press",
						link: "/reviews/press/",
						permission: "press_review.*",
					},
					{
						label: "Editorial",
						link: "/reviews/editorial/",
						permission: PermissionTarget.BRAND_EDITORIAL_REVIEW,
						securityMode: SecurityMode.NEW,
					},
				],
			},
			{
				icon: <UserIcon />,
				label: "Authors",
				link: "/authors/",
				permission: "author.*",
			},
		],
	},
	{
		label: "Media",
		subNavs: [
			{
				icon: <VideoLibraryIcon />,
				label: "Videos",
				link: "/videos/",
				permission: PermissionTarget.MEDIA_VIDEO,
				securityMode: SecurityMode.NEW,
			},
			{
				icon: <PhotoLibraryIcon />,
				label: "Images",
				link: "/images/",
				permission: PermissionTarget.MEDIA_IMAGE,
				securityMode: SecurityMode.NEW,
			},
		],
	},
	{
		label: "Social",
		permission: "social.*",
		subNavs: [
			{
				icon: <UserIcon />,
				label: "Users",
				link: "/social/users",
			},
			{
				icon: <ReviewsIcon />,
				label: "Reviews",
				link: "/social/reviews",
			},
		],
	},
	{
		label: "Showtimes",
		subNavs: [
			{
				icon: <LocalActivityIcon />,
				label: "Manual entry",
				link: "/clocks/",
				permission: "showtimes.*",
			},
			{
				icon: <ImportExportIcon />,
				label: "Imports",
				subNavs: [
					{
						label: "Configurations",
						link: "/imports/configurations/",
					},
					{
						label: "Runs",
						link: "/imports/runs/",
					},
					{
						label: "Showtimes operations",
						link: "/imports/showtimes/",
						permission: "khronos.*",
					},
					{
						label: "Crawlers",
						link: "/imports/crawlers/",
						permission: "khronos.*",
					},
				],
			},
		],
	},
	{
		label: "Tools",
		subNavs: [
			{
				icon: <MatchingIcon />,
				label: "Content Matching",
				subNavs: [
					{
						label: "Products",
						link: "/matching-tool/products",
						permission: "matching_tool.*",
					},
					{
						label: "Showtimes",
						link: "/matching-tool/showtimes",
						permission: "matching_tool_showtimes.*",
					},
				],
			},
			{
				icon: <RuleIcon />,
				label: "Duplicates",
				link: "/duplicates/",
				permission: "duplicate.*",
			},
			{
				icon: <BoxofficeIcon />,
				label: "Boxoffice Entry",
				link: "/boxoffices/",
				permission: "boxoffice.*",
			},
			{
				icon: <CountriesIcon />,
				label: "Countries",
				link: "/countries/",
				permission: "country.*",
			},
			{
				icon: <LinkIcon />,
				label: "Jump Platform",
				link: "/jump-platform",
				permission: PermissionTarget.JUMP_PLATFORM_GENERATE_LINK,
				securityMode: SecurityMode.NEW,
			},
		],
	},
	{
		label: "Administration",
		subNavs: [
			{
				icon: <UserIcon />,
				label: "Access Management",
				permission: "security.*",
				subNavs: [
					{
						label: "Users",
						link: "/security/users/",
					},
					{
						label: "Teams",
						link: "/security/teams/",
					},
					{
						label: "Presets",
						link: "/security/presets/",
					},
				],
			},
			{
				icon: <GraphIcon />,
				label: "Graph API",
				permission: "graph.*",
				subNavs: [
					{
						label: "Applications",
						link: "/graph/applications",
					},
					{
						label: "Tokens",
						link: "/graph/tokens",
					},
				],
			},
			{
				icon: <ProxyIcon />,
				label: "Graph Proxy",
				permission: "graph.*",
				subNavs: [
					{
						label: "Customers",
						link: "/proxy/customers",
					},
					{
						label: "Queries",
						link: "/proxy/queries",
					},
					{
						label: "Scopes",
						link: "/proxy/scopes",
					},
				],
			},
		],
	},
];

function MenuLink({ icon, link, label, subNavs = [] }: MenuLinkProps) {
	const labelElement = (
		<span className="inline-flex items-center gap-3">
			{icon}
			{label}
		</span>
	);

	return (
		<li className="text-gray-600">
			{link ? (
				<NavLink
					end
					to={link}
					className={({ isActive }) =>
						clsx("block hover:text-black", {
							"text-gray-800 border-r-4 border-red-600": isActive,
						})
					}
				>
					{labelElement}
				</NavLink>
			) : (
				<div className="font-semibold">{labelElement}</div>
			)}
			{subNavs.length > 0 && (
				<ul className="ml-2 pl-5 text-sm border-l border-gray-400 leading-6">
					<SubNav items={subNavs} />
				</ul>
			)}
		</li>
	);
}

function SubNav({ items = [] }: SubNavProps) {
	const allowedItems = removeNotGranted(items, useSecurity());
	if (allowedItems.length === 0) {
		return null;
	}

	return (
		<>
			{allowedItems.map((item, subIndex) => (
				<MenuLink key={subIndex} label={item.label} link={item.link} icon={item.icon} subNavs={item.subNavs} />
			))}
		</>
	);
}

type grantChecker = ReturnType<typeof useSecurity>;

function removeNotGranted(items: NavItem[], checker: grantChecker): NavItem[] {
	const filteredItems = items.filter((item) => {
		let showSubNavs = false;
		if (item.subNavs) {
			const real = removeNotGranted(item.subNavs, checker);
			showSubNavs = real.length !== 0;
		}

		// remove item if :
		// - there's a permission, and we don't have access to it
		// - there's a special check and it returns false (used for feature flags)
		// - there's nothing clickable under it :
		//    - filtered subnavs is empty
		//    - item itself is not clickable

		return (
			(item.permission !== undefined
				? (item.securityMode === SecurityMode.NEW ? checker.isGrantedNew : checker.isGranted)(item.permission)
				: true) && (item.link !== undefined ? true : showSubNavs)
		);
	});

	return filteredItems;
}

export function MainMenu({ showMenu }: MenuProps) {
	return (
		<nav
			className={clsx(
				"w-60 fixed bottom-0 top-14 py-4",
				"bg-white border-r border-gray-200",
				"overflow-auto overscroll-none",
				"hide-scrollbar",
				{
					"transform -translate-x-60": !showMenu,
				},
			)}
		>
			<ul className="pl-4 leading-10">
				<SubNav items={NAV_ITEMS} />
			</ul>
		</nav>
	);
}
