import { lightFormat, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useMemo } from "react";

const DATE_FORMAT = "yyyy-MM-dd";
const DATETIME_FORMAT = "yyyy-MM-dd, HH:mm";
const TIME_FORMAT = "HH:mm:ss";

type FormatType = "date" | "datetime" | "time";

const getFormatFromType = (type: FormatType) => {
	if (type === "datetime") return DATETIME_FORMAT;
	if (type === "time") return TIME_FORMAT;
	return DATE_FORMAT;
};

export const formatDate = (value: string | Date, type: FormatType) => {
	let date;
	if (!(typeof value === "string" || value instanceof Date)) throw new Error("formatDate:invalid parameter");

	if (typeof value === "string") date = parseISO(value);
	else date = value;

	const format = getFormatFromType(type);
	return lightFormat(date, format);
};

export const formatDateForCalendar = (value: string | Date) => {
	let d = value;
	if (typeof value === "string") d = parseISO(value);
	if (d instanceof Date) return lightFormat(d, "yyyy-MM-dd'T'HH:mm");
	throw new Error(`formatDateForCalendar:invalid parameter ${value}`);
};

export const formatDateForTZ = ({
	date,
	type,
	tz,
}: {
	date: string | Date;
	type: FormatType;
	tz?: string | null;
}) => {
	if (!(typeof date === "string" || date instanceof Date)) throw new Error("formatDateForTZ:invalid parameter");

	const d = typeof date === "string" ? new Date(date) : date;
	if (!tz) return formatDate(d, type);

	const format = getFormatFromType(type);
	return formatInTimeZone(d, tz, format);
};

const useISOFormat = (value: string | Date | null, type: FormatType) =>
	useMemo(() => (value ? formatDate(value, type) : null), [value, type]);

interface ISODateProps {
	value: string | Date | null;
}

export const ISODateTime = ({ value }: ISODateProps) => {
	const formatted = useISOFormat(value, "datetime");

	return <>{formatted ?? "-"}</>;
};

export const ISODate = ({ value, withDateTimeTooltip = false }: ISODateProps & { withDateTimeTooltip?: boolean }) => {
	const formatted = useISOFormat(value, "date");

	return (
		<span
			{...(withDateTimeTooltip && value ? { title: formatDate(value, "datetime"), className: "cursor-context-menu" } : {})}
		>
			{formatted ?? "-"}
		</span>
	);
};
