import clsx from "clsx";
import type { ReactElement } from "react";
import { cloneElement } from "react";

export interface IllustrationProps {
	children: ReactElement;
	className?: string;
}

export type IllustrationPropsWithoutChildren = Omit<IllustrationProps, "children">;

export function Illustration(props: IllustrationProps) {
	const { children, className } = props;
	return cloneElement(children, {
		contentEditable: false,
		className: clsx(className, children.props.className, "fill-current stroke-current select-none"),
	});
}
