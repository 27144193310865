import type { BrandOption } from "./components/select-brand";
import type { CountryOption } from "./components/select-country";
import type { LocaleOption } from "./components/select-locale";
import { useViewerPreferences } from "./viewer";

export function useDefaultBrand(): BrandOption | undefined {
	const preferences = useViewerPreferences();
	return preferences?.defaultBrand ?? undefined;
}

export function useDefaultCountry(): CountryOption | undefined {
	const preferences = useViewerPreferences();
	return preferences?.defaultCountry ?? undefined;
}

export function useDefaultLocale(): LocaleOption | undefined {
	const preferences = useViewerPreferences();
	return preferences?.defaultLocale ?? undefined;
}
