export function assert(condition: unknown, message?: string): asserts condition {
	if (!condition) {
		throw new Error(message ?? "Failed assertion");
	}
}

export const expect = <T>(value: T, message?: string): NonNullable<T> => {
	assert(value != null, message);
	return value;
};

export const isDefined = <T>(v: T): v is NonNullable<T> => v !== undefined && v !== null;

export const isDefinedOrNull = <T extends unknown | null>(v: T): v is T => v !== undefined || v === null;
