import type { ReactNode } from "react";
import type { Params, RouteMatch } from "react-router-dom";

import FestivalBreadCrumbTitle from "../festivals/festival-breadcrumb";
import FestivalCompetitionBreadCrumbTitle from "../festivals/festival-competition-breadcrumb";
import FestivalEditionBreadCrumbTitle from "../festivals/festival-edition-breadcrumb";
import { FestivalLocaleProvider } from "../festivals/providers/festival";

export type RouteMatchDefinition = RouteMatch & {
	route: RoutePathDefinition;
};

export type RoutePathDefinition = {
	path: string;
	label?: string | ((match: Params<string>) => string | ReactNode) | ReactNode;
	children?: RoutePathDefinition[];
	textOnly?: boolean;
	redirectToFn?: (route: RouteMatchDefinition) => string;
};

export const getParentRoutePath = ({ pathname, route: { path } }: RouteMatchDefinition) =>
	pathname.replace(new RegExp(`/${path}$`), "");

export const routes: RoutePathDefinition[] = [
	{
		path: "authors",
		label: "Authors",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `#${match.id}`,
			},
		],
	},
	{
		path: "boxoffices",
		label: "Boxoffices",
	},
	{
		path: "carousels",
		label: "Carousels",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: "slides",
				label: "Slides",
				children: [
					{
						path: "create",
						label: "Create",
					},
					{
						path: ":id",
						label: (match) => `${match.id}`,
					},
				],
			},
			{
				path: ":id",
				label: (match) => `${match.id}`,
				children: [
					{
						path: "duplicate",
						label: "Duplicate",
					},
				],
			},
		],
	},
	{
		path: "clocks",
		label: "Showtimes",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `#${match.id}`,
			},
		],
	},
	{
		path: "companies",
		label: "Companies",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `#${match.id}`,
				children: [
					{
						path: "staff",
						label: "Staff",
						children: [
							{
								path: "create",
								label: "Create",
							},
							{
								path: ":staffId",
								label: (match) => `#${match.staffId}`,
							},
						],
					},
					{
						path: "brand",
						label: "Brand & Locale data",
					},
					{
						path: "pictures",
						label: "Pictures",
					},
					{
						path: "showtimes",
						label: "Showtimes",
					},
				],
			},
		],
	},
	{
		path: "countries",
		label: "Countries",
		children: [
			{
				path: ":id",
				label: (match) => `#${match.id}`,
			},
		],
	},
	{
		path: "duplicates",
		label: "Duplicates",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `#${match.id}`,
			},
		],
	},
	{
		path: "images",
		label: "Images",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `#${match.id}`,
			},
		],
	},
	{
		path: "graph",
		textOnly: true,
		label: "Graph API",
		children: [
			{
				path: "applications",
				label: "Applications",
				children: [
					{
						path: "create",
						label: "Create",
					},
				],
			},
			{
				path: "tokens",
				label: "Tokens",
				children: [
					{
						path: "create",
						label: "Create",
					},
					{
						path: ":id",
						label: (match) => `#${match.id}`,
					},
				],
			},
		],
	},
	{
		path: "matching-tool",
		textOnly: true,
		label: "Matching tool",
		children: [
			{
				path: "products",
				label: "Products",
			},
			{
				path: "showtimes",
				label: "Showtimes",
			},
		],
	},
	{
		path: "movies",
		label: "Movies",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `#${match.id}`,
				children: [
					{
						path: "releases-and-misc",
						label: "Releases & Misc",
						children: [
							{
								path: "releases/create",
								label: "Create",
							},
							{
								path: "releases/:release",
								label: (match) => `#${match.release}`,
							},
						],
					},
					{
						path: "brand",
						label: "Brand & Locale data",
					},
					{
						path: "editorial",
						label: "Editorial",
					},
					{
						path: "showtimes",
						label: "Showtimes",
					},
					{
						path: "video-b2c",
						label: "Video B2C",
						children: [{ path: ":videoRef", label: (match) => `#${match.videoRef}` }],
					},
					{
						path: "video-b2b",
						label: "Video B2B",
						children: [{ path: ":videoRef", label: (match) => `#${match.videoRef}` }],
					},
					{
						path: "linked-persons",
						label: "Linked persons",
						children: [
							{
								path: "casting",
								textOnly: true,
								label: "Casting",
								children: [
									{
										path: "create",
										label: "Create",
										children: [
											{
												path: "create-person",
												label: "Create Person",
											},
										],
									},
									{
										path: ":casting",
										label: (match) => `#${match.casting}`,
										children: [
											{
												path: "local",
												textOnly: true,
												label: "Local",
												children: [
													{
														path: "create",
														label: "Create",
													},
													{
														path: ":local",
														label: (match) => `#${match.local}`,
													},
												],
											},
										],
									},
								],
							},
							{
								path: "credit",
								textOnly: true,
								label: "Credits",
								children: [
									{
										path: "create",
										label: "Add",
										children: [
											{
												path: "create-person",
												label: "Create Person",
											},
										],
									},
								],
							},
							{
								path: "company",
								textOnly: true,
								label: "Companies",
								children: [
									{
										path: "create",
										label: "Add",
									},
								],
							},
						],
					},
					{
						path: "images",
						label: "Images",
						children: [
							{
								path: "create",
								label: "Create",
							},
						],
					},
				],
			},
		],
	},
	{
		path: "news",
		label: "News",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `${match.id}`,
				children: [
					{
						path: "additional-info",
						label: "Additional Info",
					},
					{
						path: "publication",
						label: "Publication",
					},
					{
						path: "slide",
						textOnly: true,
						label: "Slides",
						children: [
							{
								path: "create",
								label: "Create",
							},
							{
								path: ":slide",
								label: (match) => `#${match.slide}`,
							},
						],
					},
				],
			},
		],
	},
	{
		path: "imports",
		textOnly: true,
		label: "Imports",
		children: [
			{
				path: "configurations",
				label: "Configurations",
				children: [
					{
						path: "create",
						label: "Create",
					},
					{
						path: ":id",
						label: (match) => `${match.id}`,
					},
				],
			},
			{
				path: "runs",
				label: "Runs",
				children: [
					{
						path: "create",
						label: "Create",
					},
					{
						path: ":id",
						label: (match) => `${match.id}`,
					},
				],
			},
			{
				path: "crawlers",
				label: "Crawlers",
				children: [
					{
						path: "create",
						label: "Create",
					},
					{
						path: ":id",
						label: (match) => `${match.id}`,
					},
				],
			},
			{
				path: "showtimes",
				label: "Showtimes",
			},
		],
	},
	{
		path: "proxy",
		textOnly: true,
		label: "Graph API",
		children: [
			{
				path: "customers",
				label: "Customers",
				children: [
					{
						path: "create",
						label: "Create",
					},
					{
						path: ":id",
						label: (match) => `#${match.id}`,
					},
				],
			},
			{
				path: "queries",
				label: "Queries",
				children: [
					{
						path: "create",
						label: "Create",
					},
					{
						path: ":id",
						label: (match) => `#${match.id}`,
					},
				],
			},
			{
				path: "scopes",
				label: "Scopes",
				children: [
					{
						path: "create",
						label: "Create",
					},
					{
						path: ":id",
						label: (match) => `#${match.id}`,
						children: [
							{
								path: "query/create",
								label: "Link query",
							},
							{
								path: "query/:query",
								label: (match) => `#${match.query}`,
							},
						],
					},
				],
			},
		],
	},
	{
		path: "persons",
		label: "Persons",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `#${match.id}`,
				children: [
					{
						path: "images",
						label: "Images",
						children: [
							{
								path: "create",
								label: "Create",
							},
						],
					},
					{
						path: "video-b2c",
						label: "Video B2C",
						children: [{ path: ":videoRef", label: (match) => `#${match.videoRef}` }],
					},
				],
			},
		],
	},
	{
		path: "reviews/editorial",
		label: "Editorial reviews",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `${match.id}`,
			},
		],
	},
	{
		path: "reviews/press",
		label: "Press reviews",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `${match.id}`,
			},
		],
	},
	{
		path: "security",
		textOnly: true,
		label: "Security",
		children: [
			{
				path: "presets",
				label: "Presets",
				children: [
					{
						path: "create",
						label: "Create",
					},
					{
						path: ":id",
						label: (match) => `#${match.id}`,
					},
				],
			},
			{
				path: "teams",
				label: "Teams",
				children: [
					{
						path: "create",
						label: "Create",
					},
					{
						path: ":id",
						label: (match) => `#${match.id}`,
					},
				],
			},
			{
				path: "users",
				label: "Users",
				children: [
					{
						path: "create",
						label: "Create",
					},
					{
						path: ":id",
						label: (match) => `#${match.id}`,
					},
				],
			},
		],
	},
	{
		path: "series",
		label: "Series",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":series",
				label: (match) => `Series #${match.series}`,
				children: [
					{
						path: "images",
						label: "Images",
						children: [
							{
								path: "create",
								label: "Create",
							},
						],
					},
					{
						path: "editorial",
						label: "Editorial",
					},
					{
						path: "brand",
						label: "Brand & Locale data",
					},
					{
						path: "releases-and-misc",
						label: "Releases & Misc",
						children: [
							{
								path: "releases",
								textOnly: true,
								label: "Releases",
								children: [
									{
										path: "create",
										label: "Create",
									},
									{
										path: ":release",
										label: (match) => `#${match.release}`,
									},
								],
							},
						],
					},
					{
						path: "video-b2c",
						label: "Video B2C",
						children: [{ path: ":videoRef", label: (match) => `#${match.videoRef}` }],
					},
					{
						path: "linked-persons",
						label: "Linked persons",
						children: [
							{
								path: "credit",
								textOnly: true,
								label: "Credits",
								children: [
									{
										path: "create",
										label: "Create",
										children: [
											{
												path: "create-person",
												label: "Create Person",
											},
										],
									},
								],
							},
							{
								path: "company",
								textOnly: true,
								label: "Company",
								children: [
									{
										path: "create",
										label: "Create",
									},
								],
							},
						],
					},
					{
						path: "seasons",
						children: [
							{
								path: ":season",
								label: (match) => `Season #${match.season}`,
								children: [
									{
										path: "video-b2c",
										label: "Video B2C",
										children: [
											{
												path: ":videoRef",
												label: (match) => `#${match.videoRef}`,
											},
										],
									},
									{
										path: "images",
										label: "Images",
										children: [
											{
												path: "create",
												label: "Create",
											},
										],
									},
									{
										path: "brand",
										label: "Brand & Locale data",
									},
									{
										path: "releases-and-misc",
										label: "Releases & Misc",
										children: [
											{
												path: "releases",
												textOnly: true,
												label: "Releases",
												children: [
													{
														path: "create",
														label: "Create",
													},
													{
														path: ":release",
														label: (match) => `#${match.release}`,
													},
												],
											},
										],
									},
									{
										path: "linked-persons",
										label: "Linked Persons",
										children: [
											{
												path: "casting",
												textOnly: true,
												label: "Casting",
												children: [
													{
														path: "create",
														label: "Create",
														children: [
															{
																path: "create-person",
																label: "Create Person",
															},
														],
													},
													{
														path: ":casting",
														label: (match) => `#${match.casting}`,
														children: [
															{
																path: "local",
																textOnly: true,
																label: "Local",
																children: [
																	{
																		path: "create",
																		label: "Create",
																	},
																	{
																		path: ":local",
																		label: (match) => `#${match.local}`,
																	},
																],
															},
														],
													},
												],
											},
											{
												path: "credit",
												textOnly: true,
												label: "Credits",
												children: [
													{
														path: "create",
														label: "Create",
														children: [
															{
																path: "create-person",
																label: "Create Person",
															},
														],
													},
												],
											},
											{
												path: "company",
												textOnly: true,
												label: "Company",
												children: [
													{
														path: "create",
														label: "Create",
													},
												],
											},
										],
									},
									{
										path: "editorial",
										label: "Editorial",
									},
									{
										path: "episodes",
										children: [
											{
												path: ":episode",
												label: (match) => `Episode #${match.episode}`,
												children: [
													{
														path: "images",
														label: "Images",
														children: [
															{
																path: "create",
																label: "Create",
															},
														],
													},
													{
														path: "brand",
														label: "Brand & Locale data",
													},
													{
														path: "releases-and-misc",
														label: "Releases & Misc",
														children: [
															{
																path: "releases",
																textOnly: true,
																label: "Releases",
																children: [
																	{
																		path: "create",
																		label: "Create",
																	},
																	{
																		path: ":release",
																		label: (match) => `#${match.release}`,
																	},
																],
															},
														],
													},
													{
														path: "linked-persons",
														label: "Linked Persons",
														children: [
															{
																path: "casting",
																textOnly: true,
																label: "Casting",
																children: [
																	{
																		path: "create",
																		label: "Create",
																		children: [
																			{
																				path: "create-person",
																				label: "Create Person",
																			},
																		],
																	},
																	{
																		path: ":casting",
																		label: (match) => `#${match.casting}`,
																		children: [
																			{
																				path: "local",
																				textOnly: true,
																				label: "Local",
																				children: [
																					{
																						path: "create",
																						label: "Create",
																					},
																					{
																						path: ":local",
																						label: (match) => `#${match.local}`,
																					},
																				],
																			},
																		],
																	},
																],
															},
															{
																path: "credit",
																textOnly: true,
																label: "Credits",
																children: [
																	{
																		path: "create",
																		label: "Create",
																		children: [
																			{
																				path: "create-person",
																				label: "Create Person",
																			},
																		],
																	},
																],
															},
															{
																path: "company",
																textOnly: true,
																label: "Company",
																children: [
																	{
																		path: "create",
																		label: "Create",
																	},
																],
															},
														],
													},
													{
														path: "video-b2c",
														label: "Video B2C",
														children: [
															{
																path: ":videoRef",
																label: (match) => `#${match.videoRef}`,
															},
														],
													},
												],
											},
										],
									},
								],
							},
						],
					},
				],
			},
		],
	},
	{
		path: "social",
		textOnly: true,
		label: "Social",
		children: [
			{
				path: "users",
				label: "Users",
				children: [
					{
						path: ":id",
						label: (match) => `${match.id}`,
					},
				],
			},
			{
				path: "reviews",
				label: "Reviews",
			},
		],
	},
	{
		path: "special-operations",
		label: "Special Operations",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":ref",
				label: (match) => `${match.ref}`,
				children: [
					{
						path: "create",
						label: "Create slot",
					},
					{
						path: "edit/:type",
						label: (match) => `Slot: ${match.type}`,
					},
				],
			},
		],
	},
	{
		path: "tags",
		label: "Tags",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `${match.id}`,
			},
		],
	},
	{
		path: "jump-platform",
		label: "Jump Platform",
		children: [
			{
				path: "history-list",
				label: "Generation history",
			},
			{
				path: "generate-link",
				label: "Generate link",
			},
		],
	},
	{
		path: "theaters",
		label: "Theaters",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":code",
				label: (match) => `${match.code}`,
				children: [
					{
						path: "attributes",
						label: "Attributes",
					},
					{
						path: "screens",
						label: "Screens",
					},
					{
						path: "prices",
						label: "Prices",
					},
					{
						path: "data",
						label: "Data",
					},
					{
						path: "pictures",
						label: "Pictures",
					},
					{
						path: "showtimes",
						label: "Showtimes",
					},
					{
						path: "screens/create",
						label: "Create Screen",
					},
					{
						path: "screens/:id",
						label: (match) => `Screen #${match.id}`,
					},
				],
			},
		],
	},
	{
		path: "trivias",
		label: "Trivias",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":id",
				label: (match) => `${match.id}`,
			},
		],
	},
	{
		path: "videos",
		label: "Videos",
		children: [
			{
				path: ":id",
				label: (match) => `${match.id}`,
			},
		],
	},
	{
		path: "festivals",
		label: "Festivals",
		children: [
			{
				path: "create",
				label: "Create",
			},
			{
				path: ":festival",
				label: (match) => (
					<FestivalLocaleProvider>
						<FestivalBreadCrumbTitle match={match} />
					</FestivalLocaleProvider>
				),
				children: [
					{
						path: "awards",
						label: "Awards",
					},
					{
						path: "videos",
						label: "Videos",
						children: [
							{
								path: "create",
								label: "Create video",
							},
						],
					},
					{
						path: "editions",
						label: "Editions",
						redirectToFn: getParentRoutePath,
						children: [
							{
								path: "create",
								label: "Create",
							},
							{
								path: ":edition",
								label: (match) => (
									<FestivalLocaleProvider>
										<FestivalEditionBreadCrumbTitle match={match} />
									</FestivalLocaleProvider>
								),
								children: [
									{
										path: "sections",
										label: "Sections",
										children: [
											{
												path: ":competition",
												label: (match) => (
													<FestivalLocaleProvider>
														<FestivalCompetitionBreadCrumbTitle match={match} />
													</FestivalLocaleProvider>
												),
												children: [
													{
														path: "selections",
														label: "Selections",
													},
													{
														path: "jury",
														label: "Jury",
													},
												],
											},
										],
									},
									{
										path: "prize-list",
										label: "Prize list",
									},
									{
										path: "photos",
										label: "Photos",
										children: [
											{
												path: "create",
												label: "Create photo",
											},
										],
									},
									{
										path: "videos",
										label: "Videos",
										children: [
											{
												path: "create",
												label: "Create video",
											},
										],
									},
								],
							},
						],
					},
				],
			},
		],
	},
	{
		path: "test",
		label: "Test page",
	},
];
