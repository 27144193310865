import { satisfies } from "compare-versions";
import type { ReactNode } from "react";
import { useState } from "react";
import { BrowserTypes, browserName, browserVersion, fullBrowserVersion } from "react-device-detect";

import { Alert } from "./components/alert";
import { Button } from "./components/button";
import { Modal } from "./components/modal";
import { UndoIcon } from "./icons/undo-icon";

type BrowserCheck = {
	key: string;
	version: string;
	nameFn: (name: string) => boolean;
};

const BROWSER_CHECKS: BrowserCheck[] = [
	{
		key: "Chromium-like",
		version: ">=80",
		nameFn: (n) =>
			[
				"Iron",
				"Brave",
				BrowserTypes.Chrome,
				BrowserTypes.Chromium,
				BrowserTypes.EdgeChromium,
				BrowserTypes.Yandex,
			].includes(n),
	},
	{
		key: BrowserTypes.Firefox,
		version: ">=86",
		nameFn: (n) => n === BrowserTypes.Firefox,
	},
	{
		key: "Safari-like",
		version: ">=16",
		nameFn: (n) => [BrowserTypes.Safari, BrowserTypes.MobileSafari].includes(n),
	},
	{
		key: BrowserTypes.Opera,
		version: ">=80",
		nameFn: (n) => n === BrowserTypes.Opera,
	},
	{
		key: BrowserTypes.Edge,
		version: ">=80",
		nameFn: (n) => n === BrowserTypes.Edge,
	},
	{
		key: BrowserTypes.MIUI,
		version: ">=11",
		nameFn: (n) => n === BrowserTypes.MIUI,
	},
	{
		key: BrowserTypes.SamsungBrowser,
		version: ">=10",
		nameFn: (n) => n === BrowserTypes.SamsungBrowser,
	},
];

export const BrowserCompatibilityChecker = ({
	children,
}: {
	children: ReactNode;
}) => {
	const [ack, setAck] = useState(false);

	// silence alert of acknowledged
	if (ack) return <>{children}</>;

	const browserChecks = BROWSER_CHECKS.map((sbc) => {
		const versionPass = satisfies(fullBrowserVersion, sbc.version);
		const namePass = sbc.nameFn(browserName);
		return {
			check: sbc,
			browserName,
			fullBrowserVersion,
			versionPass,
			namePass,
			pass: versionPass && namePass,
		};
	});
	const currentBrowserCheck = browserChecks.find((bc) => bc.namePass);
	const browserPass = currentBrowserCheck?.pass ?? false;

	if (browserPass) return <>{children}</>;

	return (
		<>
			<Modal>
				<Alert color="error">
					<div>Your browser does not meet minimum requirements, please upgrade...</div>
					<div className="mt-2">
						Detected browser: {browserName}, version {browserVersion}
					</div>
					{currentBrowserCheck && (
						<div>
							Check: "{currentBrowserCheck.check.key}", version requirement: "{currentBrowserCheck.check.version}"
						</div>
					)}
				</Alert>
				<div className="mt-2">
					<Alert color="success">
						More informations around&nbsp;
						<span className="hover:underline hover:underline-offset-1">
							<a
								href="https://browser-update.org/update-browser.html"
								rel="noreferrer noopener"
								target="_blank"
								className="text-blue-600 hover:text-blue-600/50 hover:underline"
							>
								how to upgrade your browser based on your platform
							</a>
						</span>
					</Alert>
				</div>
				<div className="flex justify-center">
					<Button
						onClick={() => {
							setAck(true);
						}}
						type="submit"
						color="danger"
						icon={UndoIcon}
						className="top-2"
					>
						Acknowledge and continue browsing anyway
					</Button>
				</div>
			</Modal>
			{children}
		</>
	);
};
