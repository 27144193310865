import clsx from "clsx";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";

import { KeyboardArrowRightIcon } from "@icons/keyboard-arrow-right-icon";

interface BreadcrumbsProps {
	children?: ReactNode;
	items?: Array<{
		label: string;
		href?: string;
	}>;
}

export const BreadCrumbLabel = ({ label: pLabel, maxLength = 20 }: { label?: string | null; maxLength?: number }) => {
	let label = pLabel ?? "";
	if (!pLabel) label = "...";
	label = label.trim();
	const outOfLength = label.length > maxLength;
	const fLabel = typeof maxLength === "number" ? `${label.slice(0, maxLength)}${outOfLength ? "..." : ""}` : label;
	return <span {...(outOfLength ? { title: label } : {})}>{fLabel}</span>;
};

export function Breadcrumbs(props: BreadcrumbsProps) {
	const { children, items } = props;
	return (
		<ul className="flex flex-nowrap">
			{items
				? items.map((item, index) => (
						<BreadcrumbItem key={index} href={item.href}>
							<BreadCrumbLabel label={item.label} />
						</BreadcrumbItem>
				  ))
				: children}
		</ul>
	);
}

type BreadcrumbItemProps = {
	children: ReactNode;
	href?: string;
	isRoot?: boolean;
};

export function BreadcrumbItem(props: BreadcrumbItemProps) {
	const { children, href, isRoot = false } = props;
	return (
		<li className={clsx("flex items-center h-8 text-gray-500", "last-of-type:font-semibold last-of-type:text-gray-800")}>
			{!isRoot && <KeyboardArrowRightIcon className="mx-2 text-gray-400" />}
			<span className="max-w-[30rem] truncate">
				{href ? (
					<Link to={href} className="hover:underline hover:text-gray-800">
						{children}
					</Link>
				) : (
					children
				)}
			</span>
		</li>
	);
}
