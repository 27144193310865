import type { ReactNode } from "react";

interface ErrorMessageProps {
	children?: ReactNode;
	error?: Error;
}

export function ErrorMessage({ error, children }: ErrorMessageProps) {
	return (
		<div className="flex flex-col items-center gap-4">
			<div>An error occurred preventing to display this content.</div>
			{children}
			{error ? (
				<pre className="p-2 text-sm bg-red-100 text-red-700 rounded overflow-y-auto max-w-full">{error.stack}</pre>
			) : null}
		</div>
	);
}
