import { lightFormat, parseISO } from "date-fns";

import type { Person } from "@graphql/types";

export function toShortDate(value: string | Date | null): string | null {
	if (value) return lightFormat(typeof value === "string" ? parseISO(value) : value, "yyyy-MM-dd");
	return value;
}

export function toFloat(value: string | null): number | null {
	return value === null || !value ? null : parseFloat(value);
}

export const toNullable = (value: string | undefined | null): string | undefined | null => {
	if (typeof value === "string" && value.trim() === "") return null;
	return value;
};

export function toInteger(value: string | null): number | null {
	return value === null || !value ? null : parseInt(value, 10);
}

export function round(value: number, precision: number): number {
	const offset = 10 ** precision;
	return Math.round(value * offset) / offset;
}

export function formatPartialDate(raw: string): string {
	if (!raw) return "";

	const value = raw.replace(/[^\d]/g, "");
	const parts = [];
	parts.push(value.slice(0, 4));

	if (value.length > 4) parts.push(value.slice(4, 6));
	if (value.length > 6) parts.push(value.slice(6, 8));

	return parts.join("-");
}

export function formatName(person: Pick<Person, "lastName" | "firstName">): string {
	const parts = [];
	if (person.firstName) parts.push(person.firstName);
	if (person.lastName) parts.push(person.lastName);
	return parts.join(" ");
}

export const formatSeasonNumber = (number: number) => `S${number.toString().padStart(2, "0")}`;

export const formatEpisodeNumber = (season: number, episode: number): string =>
	`${formatSeasonNumber(season)}E${episode.toString().padStart(2, "0")}`;

export function prettyPrintByteSize(size: number): string {
	if (size <= 1024) return `${size}B`;
	if (size <= 1024 ** 2) return `${(size / 1024).toFixed(0)}KB`;
	if (size <= 1024 ** 3) return `${(size / 1024 / 1024).toFixed(0)}MB`;
	return `${(size / 1024 / 1024 / 1024).toFixed(0)}GB`;
}

export const formatLabel = (label: string) => {
	const labelArr = label.split("_");

	return labelArr.length > 1 ? labelArr.slice(1).join("_") : label;
};

type FormatListArgs = {
	list: string[];
	config?: {
		locales?: string | string[];
		options?: Intl.ListFormatOptions;
	};
};

export const formatList = ({
	list,
	config = { locales: "en-GB", options: { style: "long", type: "conjunction" } },
}: FormatListArgs) => new Intl.ListFormat(config.locales, config.options).format(list);
