import type { Params } from "react-router-dom";

import { BreadCrumbLabel } from "../../components/breadcrumbs";
import { useFestivalCompetitionEntity } from "./hooks/festival-competition";

export const FestivalCompetitionBreadCrumbTitle = ({
	match,
}: {
	match: Params<string>;
}) => {
	const competitionRef = match.competition;
	if (!competitionRef) throw new Error("FestivalCompetitionBreadCrumbTitle:requirements not met");

	const { titleAttrs } = useFestivalCompetitionEntity({
		competitionRef,
	});
	return <BreadCrumbLabel label={titleAttrs?.label} />;
};

export default FestivalCompetitionBreadCrumbTitle;
