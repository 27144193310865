import { useEffect, useState } from "react";
import ReactRouterPrompt from "react-router-prompt";

import { Dialog } from "@components/dialog";
import { BlockIcon } from "@icons/block-icon";
import { RefreshIcon } from "@icons/refresh-icon";

import { usePreventNav } from "../../app/prevent-navigation-ctx";

export const PreventNavigation = () => {
	const [when, setWhen] = useState(false);
	const { forms, setForms } = usePreventNav();

	useEffect(() => {
		setWhen(forms.some((element) => element.isDirty));
	}, [forms]);

	return (
		<ReactRouterPrompt when={when}>
			{({ isActive, onConfirm, onCancel }) =>
				isActive && (
					<Dialog
						title="Some changes are unsaved"
						isDestructive
						onConfirm={() => {
							onConfirm();
							setForms([]);
						}}
						onClose={onCancel}
						closeIcon={BlockIcon}
						confirmLabel="Discard changes"
						confirmIcon={RefreshIcon}
					>
						Do you really want to leave ?
					</Dialog>
				)
			}
		</ReactRouterPrompt>
	);
};
