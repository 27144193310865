import type { IconPropsWithoutChildren } from "@components/icon";
import { Icon } from "@components/icon";

export function TagsIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<g>
					<path d="M21.41,11.58l-9-9A1.987,1.987,0,0,0,11,2H4A2.006,2.006,0,0,0,2,4v7a2,2,0,0,0,.59,1.42l9,9A1.987,1.987,0,0,0,13,22a1.955,1.955,0,0,0,1.41-.59l7-7A1.955,1.955,0,0,0,22,13,2.02,2.02,0,0,0,21.41,11.58ZM13,20.01,4,11V4h7V3.99l9,9Z" />
					<circle cx="1.5" cy="1.5" r="1.5" />
					<path d="M8.9,12.55A2.057,2.057,0,0,0,9.5,14L13,17.5,16.5,14a2.051,2.051,0,0,0-2.9-2.9l-.6.6-.6-.59a2.025,2.025,0,0,0-1.45-.61A2.053,2.053,0,0,0,8.9,12.55Z" />
				</g>
			</svg>
		</Icon>
	);
}
