import { useEffect, useState } from "react";

export enum ScriptStatus {
	LOADING,
	READY,
	ERROR,
}

const cachedScripts = new Map();

function loadScript(source: string): Promise<void> {
	let promise: Promise<void> = cachedScripts.get(source);
	if (!promise) {
		promise = new Promise<void>((resolve, reject) => {
			const script = document.createElement("script");
			script.src = source;
			script.onload = () => resolve();
			script.onerror = () => reject();
			document.body.appendChild(script);
		});
		cachedScripts.set(source, promise);
	}

	return promise;
}

export function useScript(source: string): ScriptStatus {
	const [status, setState] = useState(ScriptStatus.LOADING);

	useEffect(() => {
		loadScript(source)
			.then(() => setState(ScriptStatus.READY))
			.catch(() => setState(ScriptStatus.ERROR));
	}, [source]);

	return status;
}
