import clsx from "clsx";
import type { ReactNode } from "react";

export type StatusLightProps = {
	children?: ReactNode;
	variant: "positive" | "negative" | "notice" | "info" | "neutral";
	className?: string;
};

export function StatusLight({ children, variant, className }: StatusLightProps) {
	return (
		<div className={clsx("inline-flex items-center gap-2", className)}>
			<div
				className={clsx("rounded-full h-2.5 w-2.5 flex-grow-0 flex-shrink-0", {
					"bg-emerald-500": variant === "positive",
					"bg-red-500": variant === "negative",
					"bg-amber-500": variant === "notice",
					"bg-blue-500": variant === "info",
					"bg-gray-400": variant === "neutral",
				})}
			/>
			{children}
		</div>
	);
}
