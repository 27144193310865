import clsx from "clsx";
import type { ReactNode } from "react";
import { createContext, useContext, useEffect, useRef, useState } from "react";

import { Badge } from "./badge";
import { formatDate } from "./intl";

interface NotificationController {
	notify(type: NotificationType, message: string): void;
	close(id: number): void;
}

type NotificationType = "error" | "success" | "info" | "warning";

type Notification = {
	id: number;
	type: NotificationType;
	message: string;
	datetime: Date;
};

type NotificationRowProps = {
	stackIndex: number;
	displayTime: boolean;
	closeAfterMs: number;
	notification: Notification;
	onClose(): void;
};

const NotificationContext = createContext<NotificationController | null>(null);

function NotificationRow(props: NotificationRowProps) {
	const { notification, displayTime, closeAfterMs, onClose } = props;
	const closeCallback = useRef(onClose);

	useEffect(() => {
		const handler = setTimeout(() => {
			closeCallback.current();
		}, closeAfterMs);

		return () => {
			clearTimeout(handler);
		};
	}, [closeAfterMs]);

	const classes = clsx("flex max-w-md p-2 text-white shadow-xl overflow-y-auto rounded", {
		"bg-red-500": notification.type === "error",
		"bg-emerald-500": notification.type === "success",
		"bg-blue-500": notification.type === "info",
		"bg-amber-500": notification.type === "warning",
	});

	return (
		<div className={clsx(classes, "flex-col")} onClick={onClose}>
			{displayTime && (
				<div className="sticky top-0 left-0 text-[0.5rem] leading-4 text-left">
					{formatDate(notification.datetime, "time")}
				</div>
			)}
			<div className="text-center">{notification.message}</div>
		</div>
	);
}

type NotificationProviderProps = {
	maxStacked?: number;
	displayTime?: boolean;
	closeAfterMs?: number;
	position?: "top-left" | "top-center" | "top-right" | "bottom-left" | "bottom-center" | "bottom-right";
	children: ReactNode;
};

export function NotificationProvider(props: NotificationProviderProps) {
	const { children, position = "top-right", maxStacked = Infinity, displayTime = true, closeAfterMs = 3000 } = props;
	const nextID = useRef(0);
	const [notifications, setNotifications] = useState<Notification[]>([]);

	function notify(type: NotificationType, message: string) {
		setNotifications((notifications) => [
			...notifications,
			{
				id: nextID.current++,
				type,
				message,
				datetime: new Date(),
			},
		]);
	}

	function close(id: number) {
		setNotifications((notifications) => notifications.filter((notification) => notification.id !== id));
	}

	const shownNotifications = notifications.slice(-maxStacked);
	const remainingNotificationsCount = notifications.length - shownNotifications.length;
	const displayRemainingTotal = notifications.length > maxStacked;

	return (
		<NotificationContext.Provider value={{ notify, close }}>
			<div
				className={clsx(
					"fixed z-[100] flex flex-col gap-2",
					{
						"top-0 pt-4 ml-3 left-0 items-center": position === "top-left",
					},
					{
						"top-0 pt-4 left-[50%] translate-x-[-50%] items-center": position === "top-center",
					},
					{
						"top-0 pt-4 mr-3 right-0 items-center": position === "top-right",
					},
					{
						"bottom-0 pb-4 ml-3 left-0 items-center flex-col-reverse": position === "bottom-left",
					},
					{
						"bottom-0 pb-4 left-[50%] translate-x-[-50%] items-center flex-col-reverse": position === "bottom-center",
					},
					{
						"bottom-0 pb-4 mr-3 right-0 items-center flex-col-reverse": position === "bottom-right",
					},
					{
						hidden: notifications.length === 0,
					},
				)}
			>
				<div className="flex flex-col gap-2">
					{shownNotifications.map((notification, i) => (
						<NotificationRow
							key={notification.id}
							stackIndex={i}
							closeAfterMs={closeAfterMs}
							displayTime={displayTime}
							notification={notification}
							onClose={() => {
								close(notification.id);
							}}
						/>
					))}
				</div>
				<div>
					<Badge
						color="info"
						className={clsx({
							"shadow-xl": true,
							"opacity-0": !displayRemainingTotal,
							"animate-bounce": displayRemainingTotal,
						})}
					>
						{remainingNotificationsCount} queued notifications
					</Badge>
				</div>
			</div>
			{children}
		</NotificationContext.Provider>
	);
}

export function useNotifications(): NotificationController {
	const context = useContext(NotificationContext);
	if (!context) throw new Error("Cannot find NotificationContext");
	return context;
}
