import { NotFound as NotFoundIllustration } from "@illustrations/not-found";
import { Unauthorized as UnauthorizedIllustration } from "@illustrations/unauthorized";
import { Unavailable as UnavailableIllustration } from "@illustrations/unavailable";

const classes = {
	root: "flex flex-col items-center justify-center",
	illustration: "text-gray-500 mb-6",
	heading: "text-2xl font-light mb-1",
	content: "text-sm italic",
};

export function NotFound() {
	return (
		<div className={classes.root}>
			<NotFoundIllustration className={classes.illustration} />
			<div className={classes.heading}>Page not found</div>
			<div className={classes.content}>This page isn't available. Try checking the URL or visit a different page.</div>
		</div>
	);
}

export function Unauthorized() {
	return (
		<div className={classes.root}>
			<UnauthorizedIllustration className={classes.illustration} />
			<div className={classes.heading}>Access not allowed</div>
			<div className={classes.content}>
				You do not have permission to access this page. Try checking the URL or visit a different page.
			</div>
		</div>
	);
}

interface UnavailableProps {
	error?: Error;
}

export function Unavailable(props: UnavailableProps) {
	const { error } = props;
	return (
		<div className={classes.root}>
			<UnavailableIllustration className={classes.illustration} />
			<div className={classes.heading}>Internal error</div>
			<div className={classes.content}>This page isn't working. Try a different page or try again later.</div>
			{error ? <pre className="p-2 mt-6 text-sm bg-red-100 text-red-700 rounded">{error.stack}</pre> : null}
		</div>
	);
}
