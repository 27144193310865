import { useHotkey } from "./use-hotkey";

import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { isEmpty } from "../utils/check";

export const DEBOUNCE_SHORT_DELAY = 500;
export const DEBOUNCE_NORMAL_DELAY = 1000;
export const DEBOUNCE_LONG_DELAY = 2000;

/**
 * @deprecated prefer useRevampedDebounce
 * Hook to debounce callbacks, mainly searches, but immediate if pressed on Enter key
 */
export const useDebounce = <T>(value: T, pDelay: number = DEBOUNCE_NORMAL_DELAY): T => {
	const [debouncedValue, setDebouncedValue] = useState(value);
	const [immediate, setImmediate] = useState(false);
	const [delay, setDelay] = useState(pDelay);

	useHotkey(
		"Enter",
		useCallback((_event, hotkey) => {
			if (hotkey === "Enter") setImmediate(true);
		}, []),
	);
	// biome-ignore lint/correctness/useExhaustiveDependencies: we do not want to use dely as a dep here as it messes the hook behaviour
	useEffect(() => {
		if (immediate) {
			setDelay(0);
			const immTimer = setTimeout(() => {
				setDelay(pDelay);
				setImmediate(false);
			}, 0);
			return () => {
				setImmediate(false);
				clearTimeout(immTimer);
			};
		}
		return;
	}, [immediate]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);
		return () => {
			clearTimeout(timer);
		};
	}, [delay, value]);

	return debouncedValue;
};

/**
 * Hook to debounce callbacks, mainly searches, but immediate if pressed on Enter key
 */
export const useRevampedDebounce = <T>(value: T, pDelay: number = DEBOUNCE_NORMAL_DELAY): T | undefined => {
	const [debouncedValue, setDebouncedValue] = useState<T | undefined>();

	const debounced = useDebouncedCallback(
		useCallback((v) => {
			setDebouncedValue(v);
		}, []),
		pDelay,
		{ leading: true },
	);

	useHotkey(
		"Enter",
		useCallback(
			(_event, hotkey) => {
				if (hotkey === "Enter") debounced.flush();
			},
			[debounced],
		),
	);

	useEffect(() => {
		if (isEmpty(value)) debounced.flush();
	}, [value, debounced]);

	debounced(value);
	return debouncedValue;
};
