import type { IconPropsWithoutChildren } from "@components/icon";
import { Icon } from "@components/icon";

export function TriviaIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<g>
					<g>
						<path
							d="M20,3H4A2.006,2.006,0,0,0,2,5V19a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,20,3Zm0,16H4V5H20Z"
							fillRule="evenodd"
						/>
						<rect width="5.802" height="2" transform="translate(5.578 7)" />
						<rect width="5.802" height="2" transform="translate(5.578 11)" />
						<rect width="5.802" height="2" transform="translate(5.578 15)" />
					</g>
					<path d="M14.51,7h2V9h-2Zm0,4h2v6h-2Z" />
				</g>
			</svg>
		</Icon>
	);
}
