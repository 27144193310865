import { useEffect, useState } from "react";

export function clearStorage(): void {
	localStorage.clear();
}

export function removeFromStorage(key: string): void {
	localStorage.removeItem(key);
}

export function readFromStorage<T>(key: string): T | null {
	const value = localStorage.getItem(key);
	if (value != null) {
		return JSON.parse(value);
	}
	return null;
}

export function writeToStorage<T>(key: string, value: T): void {
	if (value != null) {
		localStorage.setItem(key, JSON.stringify(value));
	} else {
		localStorage.removeItem(key);
	}
}

export function useStorage<T>(key: string, initialValues: T): T {
	const [value, setValue] = useState<T>(() => readFromStorage(key) || initialValues);
	useEffect(() => {
		function handleStorage(event: StorageEvent) {
			if (event.key === key) {
				setValue(readFromStorage(key) || initialValues);
			}
		}
		window.addEventListener("storage", handleStorage);

		return () => {
			window.removeEventListener("storage", handleStorage);
		};
	}, [key, initialValues]);

	return value;
}
