import type { IconPropsWithoutChildren } from "@components/icon";
import { Icon } from "@components/icon";

export function KeyboardArrowRightIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<path d="M9.4 18 8 16.6 12.6 12 8 7.4 9.4 6 15.4 12Z" />
			</svg>
		</Icon>
	);
}
