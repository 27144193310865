import { Badge } from "../../../../components/badge";
import { StatusLight } from "../../../../components/status-light";
import { BrandOptionFragment, Genre, MovieSearchEntityFragment } from "../../../../graphql/types";
import { MovieIcon } from "../../../../icons/movie-icon";
import { formatName } from "../../../../utils/formatters";

interface MovieListItemProps {
	movie: MovieSearchEntityFragment;
	brand?: BrandOptionFragment;
}

const MovieListItem = ({ movie, brand }: MovieListItemProps) => {
	const { ref, activeOnBrands, brandData, credits, title, productionYear } = movie;

	const director = credits.nodes[0]?.person ?? null;
	const poster = brandData?.poster ?? movie.poster;
	const isActiveOnBrand = activeOnBrands?.some((element) => element.ref === brand?.ref);
	const genres = movie.genres.filter((genre) => genre !== Genre.Unknown);

	return (
		<div className="flex gap-3">
			<span className="basis-16 shrink-0">
				<img
					alt="Poster"
					className="rounded aspect-poster w-16"
					src={poster ? `${__ENV__.IMAGE_CDN_URL}/c_74_100/${poster.path}` : "/images/default-image.png"}
				/>
			</span>
			<div className="flex flex-col gap-1">
				<div className="flex gap-2 font-semibold text-wrap">
					<span>
						<MovieIcon className="shrink-0" size="S" />
					</span>
					{brand !== undefined && (
						<span>
							<StatusLight variant={isActiveOnBrand ? "positive" : "negative"} />
						</span>
					)}
					<span>
						{title} <span className="text-xs">({ref})</span>
					</span>
				</div>

				{productionYear ? (
					<div className="flex gap-1 text-xs gray-500">
						<span>{director ? formatName(director) : "Unknown director"}</span>
						<span className="font-semibold">{productionYear}</span>
					</div>
				) : null}

				{brandData?.title && (
					<div className="flex gap-1 text-xs gray-500">
						<Badge>{brandData.brand.ref}</Badge>
						<span className="truncate">{brandData.title}</span>
					</div>
				)}

				{genres.length > 0 && (
					<div className="flex flex-wrap gray-500 gap-1">
						{genres.map((genre) => (
							<Badge key={genre}>{genre}</Badge>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default MovieListItem;
