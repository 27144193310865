import clsx from "clsx";
import type { ReactNode } from "react";

import type { AlignmentProps, ItemAlignmentProps } from "./alignment";
import { getAlignmentClasses, getItemAlignmentClasses } from "./alignment";

type GridCols = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "auto" | "fr" | "min" | "max" | "none";

type GridFlow = "col" | "row" | "row-dense" | "col-dense";

type GridRows = 1 | 2 | 3 | 4 | 5 | 6 | "auto" | "fr" | "min" | "max" | "none";

type GridGap = "none" | "small" | "medium" | "large";

interface GridProps extends AlignmentProps {
	children?: ReactNode;
	cols?: GridCols;
	colsLg?: GridCols;
	colsMd?: GridCols;
	colsSm?: GridCols;
	flow?: GridFlow;
	gap?: GridGap;
	gapX?: GridGap;
	gapY?: GridGap;
	rows?: GridRows;
	className?: string;
}

/**
 * @deprecated use tailwind directly
 */
export function Grid({
	children,
	cols = 1,
	colsLg,
	colsMd,
	colsSm,
	flow,
	gap,
	rows = 1,
	alignContent,
	alignItems,
	justify,
	justifyItems,
	className,
	...rest
}: GridProps) {
	const { gapX = gap, gapY = gap } = rest;
	return (
		<div
			className={clsx(
				className,
				"grid",
				{
					"auto-cols-auto": cols === "auto",
					"auto-cols-fr": cols === "fr",
					"auto-cols-max": cols === "max",
					"auto-cols-min": cols === "min",
					"grid-cols-1": cols === 1,
					"grid-cols-10": cols === 10,
					"grid-cols-11": cols === 11,
					"grid-cols-12": cols === 12,
					"grid-cols-2": cols === 2,
					"grid-cols-3": cols === 3,
					"grid-cols-4": cols === 4,
					"grid-cols-5": cols === 5,
					"grid-cols-6": cols === 6,
					"grid-cols-7": cols === 7,
					"grid-cols-8": cols === 8,
					"grid-cols-9": cols === 9,
					"grid-cols-none": cols === "none",
				},
				{
					"sm:auto-cols-auto": colsSm === "auto",
					"sm:auto-cols-fr": colsSm === "fr",
					"sm:auto-cols-max": colsSm === "max",
					"sm:auto-cols-min": colsSm === "min",
					"sm:grid-cols-1": colsSm === 1,
					"sm:grid-cols-10": colsSm === 10,
					"sm:grid-cols-11": colsSm === 11,
					"sm:grid-cols-12": colsSm === 12,
					"sm:grid-cols-2": colsSm === 2,
					"sm:grid-cols-3": colsSm === 3,
					"sm:grid-cols-4": colsSm === 4,
					"sm:grid-cols-5": colsSm === 5,
					"sm:grid-cols-6": colsSm === 6,
					"sm:grid-cols-7": colsSm === 7,
					"sm:grid-cols-8": colsSm === 8,
					"sm:grid-cols-9": colsSm === 9,
					"sm:grid-cols-none": colsSm === "none",
				},
				{
					"md:auto-cols-auto": colsMd === "auto",
					"md:auto-cols-fr": colsMd === "fr",
					"md:auto-cols-max": colsMd === "max",
					"md:auto-cols-min": colsMd === "min",
					"md:grid-cols-1": colsMd === 1,
					"md:grid-cols-10": colsMd === 10,
					"md:grid-cols-11": colsMd === 11,
					"md:grid-cols-12": colsMd === 12,
					"md:grid-cols-2": colsMd === 2,
					"md:grid-cols-3": colsMd === 3,
					"md:grid-cols-4": colsMd === 4,
					"md:grid-cols-5": colsMd === 5,
					"md:grid-cols-6": colsMd === 6,
					"md:grid-cols-7": colsMd === 7,
					"md:grid-cols-8": colsMd === 8,
					"md:grid-cols-9": colsMd === 9,
					"md:grid-cols-none": colsMd === "none",
				},
				{
					"lg:auto-cols-auto": colsLg === "auto",
					"lg:auto-cols-fr": colsLg === "fr",
					"lg:auto-cols-max": colsLg === "max",
					"lg:auto-cols-min": colsLg === "min",
					"lg:grid-cols-1": colsLg === 1,
					"lg:grid-cols-10": colsLg === 10,
					"lg:grid-cols-11": colsLg === 11,
					"lg:grid-cols-12": colsLg === 12,
					"lg:grid-cols-2": colsLg === 2,
					"lg:grid-cols-3": colsLg === 3,
					"lg:grid-cols-4": colsLg === 4,
					"lg:grid-cols-5": colsLg === 5,
					"lg:grid-cols-6": colsLg === 6,
					"lg:grid-cols-7": colsLg === 7,
					"lg:grid-cols-8": colsLg === 8,
					"lg:grid-cols-9": colsLg === 9,
					"lg:grid-cols-none": colsLg === "none",
				},
				{
					"auto-rows-auto": rows === "auto",
					"auto-rows-fr": rows === "fr",
					"auto-rows-max": rows === "max",
					"auto-rows-min": rows === "min",
					"grid-row-1": rows === 1,
					"grid-row-2": rows === 2,
					"grid-row-3": rows === 3,
					"grid-row-4": rows === 4,
					"grid-row-5": rows === 5,
					"grid-row-6": rows === 6,
					"grid-row-none": rows === "none",
				},
				{
					"grid-flow-col-dense": flow === "col-dense",
					"grid-flow-col": flow === "col",
					"grid-flow-row-dense": flow === "row-dense",
					"grid-flow-row": flow === "row",
				},
				{
					"gap-x-0": gapX === "none",
					"gap-x-2": gapX === "small",
					"gap-x-4": gapX === "medium",
					"gap-x-8": gapX === "large",
				},
				{
					"gap-y-0": gapY === "none",
					"gap-y-2": gapY === "small",
					"gap-y-4": gapY === "medium",
					"gap-y-8": gapY === "large",
				},
				...getAlignmentClasses({
					alignContent,
					justifyItems,
					justify,
					alignItems,
				}),
			)}
		>
			{children}
		</div>
	);
}

type Span = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | "full" | "auto";
type Start = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | "auto";
type End = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | "auto";

interface GridItemProps extends ItemAlignmentProps {
	children?: ReactNode;
	colSpan?: Span;
	colStart?: Start;
	colEnd?: End;
	rowSpan?: Span;
	rowStart?: Start;
	rowEnd?: End;
	className?: string;
}

export function GridItem({
	children,
	colEnd,
	colSpan,
	colStart,
	rowSpan,
	rowStart,
	rowEnd,
	alignSelf,
	justifySelf,
	className,
}: GridItemProps) {
	return (
		<div
			className={clsx(
				className,
				{
					"col-auto": colSpan === "auto",
					"col-span-1": colSpan === 1,
					"col-span-2": colSpan === 2,
					"col-span-3": colSpan === 3,
					"col-span-4": colSpan === 4,
					"col-span-5": colSpan === 5,
					"col-span-6": colSpan === 6,
					"col-span-7": colSpan === 7,
					"col-span-8": colSpan === 8,
					"col-span-9": colSpan === 9,
					"col-span-10": colSpan === 10,
					"col-span-11": colSpan === 11,
					"col-span-12": colSpan === 12,
					"col-span-full": colSpan === "full",
					"col-start-1": colStart === 1,
					"col-start-2": colStart === 2,
					"col-start-3": colStart === 3,
					"col-start-4": colStart === 4,
					"col-start-5": colStart === 5,
					"col-start-6": colStart === 6,
					"col-start-7": colStart === 7,
					"col-start-8": colStart === 8,
					"col-start-9": colStart === 9,
					"col-start-10": colStart === 10,
					"col-start-11": colStart === 11,
					"col-start-12": colStart === 12,
					"col-start-13": colStart === 13,
					"col-start-auto": colStart === "auto",
					"col-end-1": colEnd === 1,
					"col-end-2": colEnd === 2,
					"col-end-3": colEnd === 3,
					"col-end-4": colEnd === 4,
					"col-end-5": colEnd === 5,
					"col-end-6": colEnd === 6,
					"col-end-7": colEnd === 7,
					"col-end-8": colEnd === 8,
					"col-end-9": colEnd === 9,
					"col-end-10": colEnd === 10,
					"col-end-11": colEnd === 11,
					"col-end-12": colEnd === 12,
					"col-end-13": colEnd === 13,
					"col-end-auto": colEnd === "auto",
				},
				{
					"row-auto": rowSpan === "auto",
					"row-span-1": rowSpan === 1,
					"row-span-2": rowSpan === 2,
					"row-span-3": rowSpan === 3,
					"row-span-4": rowSpan === 4,
					"row-span-5": rowSpan === 5,
					"row-span-6": rowSpan === 6,
					"row-span-7": rowSpan === 7,
					"row-span-8": rowSpan === 8,
					"row-span-9": rowSpan === 9,
					"row-span-10": rowSpan === 10,
					"row-span-11": rowSpan === 11,
					"row-span-12": rowSpan === 12,
					"row-span-full": rowSpan === "full",
					"row-start-1": rowStart === 1,
					"row-start-2": rowStart === 2,
					"row-start-3": rowStart === 3,
					"row-start-4": rowStart === 4,
					"row-start-5": rowStart === 5,
					"row-start-6": rowStart === 6,
					"row-start-7": rowStart === 7,
					"row-start-8": rowStart === 8,
					"row-start-9": rowStart === 9,
					"row-start-10": rowStart === 10,
					"row-start-11": rowStart === 11,
					"row-start-12": rowStart === 12,
					"row-start-13": rowStart === 13,
					"row-start-auto": rowStart === "auto",
					"row-end-1": rowEnd === 1,
					"row-end-2": rowEnd === 2,
					"row-end-3": rowEnd === 3,
					"row-end-4": rowEnd === 4,
					"row-end-5": rowEnd === 5,
					"row-end-6": rowEnd === 6,
					"row-end-7": rowEnd === 7,
					"row-end-8": rowEnd === 8,
					"row-end-9": rowEnd === 9,
					"row-end-10": rowEnd === 10,
					"row-end-11": rowEnd === 11,
					"row-end-12": rowEnd === 12,
					"row-end-13": rowEnd === 13,
					"row-end-auto": rowEnd === "auto",
				},
				...getItemAlignmentClasses({ justifySelf, alignSelf }),
			)}
		>
			{children}
		</div>
	);
}
