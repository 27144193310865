// todo: this file is a copy of the backend PermissionTarget, it will be removed when the 2 projects will reside in the same turbo repo
// todo: explanation: unable to get internal values of the Permission GraphQL enum, and permissions are stored in raw format in the jwt

export enum PermissionTarget {
	// Static data
	STATIC_BRAND = "static.brand",
	STATIC_COUNTRY = "static.country",
	STATIC_LOCALE = "static.locale",
	STATIC_LOCALIZATION = "static.localization",

	// Global entities
	COMPANY = "company",
	MOVIE = "movie",
	PERSON = "person",
	SERIES = "series",
	THEATER = "theater",
	FESTIVAL = "festival",

	// Entity overrides
	ENTITY_BRAND_DATA = "entity.brand_data",
	ENTITY_LOCALE_DATA = "entity.locale_data",
	ENTITY_SEO_DATA = "entity.seo_data",

	// Branded entities
	BRAND_AUTHOR = "brand.author",
	BRAND_CAROUSEL = "brand.carousel",
	BRAND_EDITORIAL_REVIEW = "brand.editorial_review",
	BRAND_SPECIAL_OPERATION = "brand.special_operation",
	BRAND_NEWS = "brand.news",
	BRAND_PRESS_REVIEW = "brand.press_review",
	BRAND_TAG = "brand.tag",
	BRAND_TRIVIA = "brand.trivia",

	// Media
	MEDIA_IMAGE = "media.image",
	MEDIA_VIDEO = "media.video",

	// Misc
	BOXOFFICE = "boxoffice",
	DUPLICATE = "duplicate",
	PRODUCT_MATCHING = "product.matching",

	// Social
	SOCIAL_USER = "social.user",
	SOCIAL_USER_REVIEW = "social.user_review",

	// Khronos
	KHRONOS_IMPORT_CRAWLER = "khronos.import_crawler",
	KHRONOS_IMPORT_CONFIGURATION = "khronos.import_configuration",
	KHRONOS_IMPORT_RUN = "khronos.import_run",
	KHRONOS_SHOWTIME = "khronos.showtime",

	// Showtimes
	SHOWTIME_CLOCK = "showtime.clock",
	SHOWTIME_MATCHING = "showtime.matching",

	// Graph API & Proxy
	GRAPH_API_APPLICATION = "graph_api.application",
	GRAPH_API_TOKEN = "graph_api.token",
	GRAPH_API_PROXY = "graph_api.proxy",

	// Jump platform
	JUMP_PLATFORM_GENERATE_LINK = "jump_platform.generate_link",

	// Security
	SECURITY_PRESET = "security.preset",
	SECURITY_USER = "security.user",
}
