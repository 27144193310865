import { useEffect, useId, useRef, useState } from "react";

import { ScriptStatus, useScript } from "@hooks/use-script";
import { useSignIn } from "@utils/auth";

import fetch from "../../utils/fetch";

const GOOGLE_GSI_CLIENT_URL = "https://accounts.google.com/gsi/client";

function Login() {
	const scriptStatus = useScript(GOOGLE_GSI_CLIENT_URL);
	const parentId = useId();
	const buttonRef = useRef<HTMLDivElement>(null);
	const signIn = useSignIn();
	const [error, setError] = useState<string>();

	useEffect(() => {
		if (scriptStatus === ScriptStatus.READY) {
			// biome-ignore lint/correctness/noUndeclaredVariables: To fix.
			google.accounts.id.initialize({
				client_id: __ENV__.GOOGLE_CLIENT_ID,
				prompt_parent_id: parentId,
				callback: async ({ credential }) => {
					try {
						const response = await fetch(new URL("/google-login", __ENV__.GRAPH_BASE_URL).toString(), {
							method: "POST",
							body: JSON.stringify({
								token: credential,
							}),
							headers: {
								"Content-Type": "application/json",
							},
						});
						if (!response.ok) {
							if (response.status === 403) throw new Error("Unauthorized user");
							throw new Error("Unexpected error");
						}
						const loginData = await response.json();
						signIn(loginData.token);
					} catch (e) {
						console.error(e);
						if (e instanceof Error) setError(e.message);
					}
				},
			});

			if (buttonRef.current) {
				// biome-ignore lint/correctness/noUndeclaredVariables: To fix.
				google.accounts.id.renderButton(buttonRef.current, {
					type: "standard",
					shape: "circle",
					theme: "outline",
					locale: "en",
				});
			}
			// biome-ignore lint/correctness/noUndeclaredVariables: To fix.
			google.accounts.id.prompt();
		}
	}, [scriptStatus, parentId, signIn]);

	return (
		<div className="grid grid-cols-2 h-screen">
			<div className="bg-[#e41e2d] p-2" id={parentId} />
			<div className="bg-white flex flex-col items-center justify-evenly gap-8">
				<img className="w-1/3" src="/images/boxoffice-logo.svg" alt="Boxoffice CMS" />
				<div className="flex flex-col items-center gap-4">
					<div ref={buttonRef} />
					{error ? <div className="text-red-700">{error}</div> : null}
				</div>
			</div>
		</div>
	);
}

export default Login;
