import type { IconPropsWithoutChildren } from "@components/icon";
import { Icon } from "@components/icon";

export function ReviewsIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<path d="M22,9.24l-7.19-.61L12,2,9.19,8.63,2,9.24l5.46,4.73L5.82,21,12,17.27,18.18,21l-1.64-7.03Zm-7.41,5.2.56,2.41-2.12-1.28L12,14.93V7.13l.97,2.29.47,1.11,1.2.1,2.47.21-1.88,1.63-.91.79Z" />
			</svg>
		</Icon>
	);
}
