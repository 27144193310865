import clsx from "clsx";
import type { HTMLAttributes } from "react";
import { useState } from "react";

import { useHotkey } from "@hooks/use-hotkey";

import { Backdrop } from "./backdrop";
import { Button } from "./button";
import { ErrorBoundary } from "./error-boundary";
import { ErrorMessage } from "./error-message";
import { Portal } from "./portal";

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
	isFullScreen?: boolean;
	onClose?: () => void;
}

export function Modal(props: ModalProps) {
	const { children, className, onClose, isFullScreen = false, ...rest } = props;

	useHotkey("Escape", () => {
		onClose?.();
	});

	const [isClosable, setClosable] = useState(true);

	return (
		<Portal>
			<Backdrop
				onClick={() => {
					if (isClosable && onClose) onClose();
				}}
				onMouseDown={() => {
					setClosable(true);
				}}
			>
				<div
					className={clsx(
						className,
						"relative w-auto m-auto bg-white p-4 rounded-md overflow-y-auto cursor-default max-w-[calc(100vh-10rem)]",
						"max-h-[calc(100vh-10rem)]",
						isFullScreen ? "max-w-[80%]" : "sm:max-w-lg md:max-w-3xl max-w-md",
					)}
					onClick={(event) => event.stopPropagation()}
					onMouseDown={() => {
						setClosable(false);
					}}
					{...rest}
				>
					<ErrorBoundary
						fallbackRender={({ error }) => (
							<div className="flex flex-col gap-2">
								<ErrorMessage error={error} />
								<div className="flex gap-4 justify-end">
									<Button onClick={onClose}>Close</Button>
								</div>
							</div>
						)}
					>
						{children}
					</ErrorBoundary>
				</div>
			</Backdrop>
		</Portal>
	);
}
