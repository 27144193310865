import type { IconPropsWithoutChildren } from "@components/icon";
import { Icon } from "@components/icon";

export function ScreenIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<path d="M21,3H3A1.993,1.993,0,0,0,1,5V17a2,2,0,0,0,2,2H8v2h8V19h5a2,2,0,0,0,1.99-2L23,5A2,2,0,0,0,21,3Zm0,14H3V5H21Z" />
			</svg>
		</Icon>
	);
}
