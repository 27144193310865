import type { Entities, MinimumIdentifiableEntity, MinimumReferentiableEntity } from "../types/entities";
import {
	isAuthorEntity,
	isCompanyEntity,
	isCompanyStaffEntity,
	isFestivalAwardEntity,
	isFestivalCompetitionEntity,
	isFestivalEditionEntity,
	isMinimumReferentiableEntity,
	isPersonEntity,
	isSeriesEpisodeEntity,
	isSeriesSeasonEntity,
} from "../types/entities";
import { formatName } from "./formatters";
import { getGlobalEntityTitleAttrs } from "./global-titles";

export type LinkableGlobalEntityFn = (entity: MinimumReferentiableEntity) => {
	to: string;
	label?: string;
	subRoutes?: string[];
};

export enum FestivalCompetitionRoutes {
	SELECTIONS = "/selections",
}

export const LINKS_ENTITY_MAPPING: Partial<{
	[_K in Entities]: LinkableGlobalEntityFn;
}> = {
	Author: (entity) => {
		if (!isAuthorEntity(entity)) throw new Error("Author global link requirements failed");
		return {
			to: `/authors/${entity.ref}`,
			label: getGlobalEntityTitleAttrs(entity).entityLabel,
		};
	},
	Company: (entity) => {
		if (!isCompanyEntity(entity)) throw new Error("Company global link requirements failed");

		return {
			to: `/companies/${entity.ref}`,
			label: getGlobalEntityTitleAttrs(entity).entityLabel,
		};
	},
	CompanyStaff: (entity) => {
		if (!isCompanyStaffEntity(entity)) throw new Error("CompanyStaff global link requirements failed");

		return {
			to: `/companies/${entity.company.ref}/staff/${entity.ref}`,
			label: getGlobalEntityTitleAttrs(entity).entityLabel,
		};
	},
	Festival: (entity) => ({
		to: `/festivals/${entity.ref}`,
		label: getGlobalEntityTitleAttrs(entity).entityLabel,
	}),
	FestivalEdition: (entity) => {
		if (!isFestivalEditionEntity(entity)) throw new Error("FestivalEdition global link requirements failed");

		return {
			to: `/festivals/${entity.festival.ref}/editions/${entity.ref}`,
			label: getGlobalEntityTitleAttrs(entity).entityLabel,
		};
	},
	FestivalCompetition: (entity) => {
		if (!isFestivalCompetitionEntity(entity)) throw new Error("FestivalCompetition global link requirements failed");

		let to = `/festivals/${entity.festival.ref}`;
		if (entity.festivalEdition?.ref) to = `${to}/editions/${entity.festivalEdition.ref}/sections/${entity.ref}`;

		return {
			to,
			label: getGlobalEntityTitleAttrs(entity).entityLabel,
			subRoutes: [FestivalCompetitionRoutes.SELECTIONS],
		};
	},
	FestivalAward: (entity) => {
		if (!isFestivalAwardEntity(entity)) throw new Error("FestivalAward global link requirements failed");

		return {
			to: `/festivals/${entity.festival.ref}/awards`,
			label: getGlobalEntityTitleAttrs(entity).entityLabel,
		};
	},
	Movie: (entity) => ({
		to: `/movies/${entity.ref}`,
		label: getGlobalEntityTitleAttrs(entity).entityLabel,
	}),
	News: (entity) => ({
		to: `/news/${entity.ref}`,
		label: getGlobalEntityTitleAttrs(entity).entityLabel,
	}),
	Person: (entity) => {
		if (!isPersonEntity(entity)) throw new Error("PersonEntity global link requirements failed");

		return {
			to: `/persons/${entity.ref}`,
			label: formatName(entity),
		};
	},
	Series: (entity) => ({
		to: `/series/${entity.ref}`,
		label: getGlobalEntityTitleAttrs(entity).entityLabel,
	}),
	SeriesSeason: (entity) => {
		if (!isSeriesSeasonEntity(entity)) throw new Error("SeriesSeason global link requirements failed");

		return {
			to: `/series/${entity.series.ref}/seasons/${entity.ref}`,
			label: getGlobalEntityTitleAttrs(entity).entityLabel,
		};
	},
	SeriesEpisode: (entity) => {
		if (!isSeriesEpisodeEntity(entity)) throw new Error("SeriesEpisode global link requirements failed");

		return {
			to: `/series/${entity.series.ref}/seasons/${entity.season?.ref ?? "-"}/episodes/${entity.ref}`,
			label: getGlobalEntityTitleAttrs(entity).entityLabel,
		};
	},
	Slide: ({ ref, __typename }) => ({
		to: `/carousels/slides/${ref}`,
		label: `${__typename} #${ref}`,
	}),
	VideoLegacy: (entity) => ({
		to: `/videos/${entity.ref}`,
		label: getGlobalEntityTitleAttrs(entity).entityLabel,
	}),
	Theater: (entity) => ({
		to: `/theaters/${entity.ref}`,
		label: getGlobalEntityTitleAttrs(entity).entityLabel,
	}),
};

export const getGlobalEntityLinkAttrs = <E extends MinimumIdentifiableEntity>(
	entity: E,
	subRoute?: string,
	defaultLabel = "-",
) => {
	if (!isMinimumReferentiableEntity(entity)) throw new Error("GlobalEntityRoute:minimum requirements not met");
	if (!(entity.__typename in LINKS_ENTITY_MAPPING))
		throw new Error(`GlobalEntityRoute:unsupported type ${entity.__typename}`);

	const { to, label, subRoutes } = (LINKS_ENTITY_MAPPING[entity.__typename as Entities] as LinkableGlobalEntityFn)(
		entity,
	);
	if (subRoute && !subRoutes?.includes(subRoute))
		throw new Error(`GlobalEntityRoute:unsupported subRoute ${subRoute} for type ${entity.__typename}`);

	const entityTitles = getGlobalEntityTitleAttrs(entity);
	return {
		to: subRoute ? `${to}${subRoute}` : to,
		...entityTitles,
		// use label or fallback to general title label
		label: label ?? entityTitles.fullLabel ?? defaultLabel,
	};
};
