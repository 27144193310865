import type { IconPropsWithoutChildren } from "@components/icon";
import { Icon } from "@components/icon";

export function CarouselIcon(props: IconPropsWithoutChildren) {
	return (
		<Icon {...props}>
			<svg viewBox="0 0 24 24">
				<path d="M2,6H6V17H2ZM7,19H17V4H7ZM9,6h6V17H9Zm9,0h4V17H18Z" />
			</svg>
		</Icon>
	);
}
