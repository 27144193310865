import type { RequestInitWithRetry } from "fetch-retry";
import fetchRetry from "fetch-retry";

const MAX_REQ_RETRIES = 30;

const retryDelay: RequestInitWithRetry["retryDelay"] = (attempt) => 2 ** attempt * 1000;

const retryOn: RequestInitWithRetry["retryOn"] = (attempt, error, response) => {
	if (attempt >= MAX_REQ_RETRIES) return false;
	if (error !== null || (response?.status ?? 0) >= 500) return true;
	return false;
};

const retriableFetch = fetchRetry(fetch, {
	retries: 30,
	retryOn,
	retryDelay,
});

export default retriableFetch;
