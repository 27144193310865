import clsx from "clsx";
import type { ReactNode } from "react";

import { FormChangeState, useFormChangeState } from "@hooks/use-form-change-state";
import { usePreventNavigation } from "@hooks/use-prevent-navigation";

import { WarningIcon } from "../icons/warning-icon";
import { ErrorBoundary } from "./error-boundary";

export type CardProps = {
	isPreventNavigation?: boolean;
	wrapperClassName?: string;
	className?: string;
	children: ReactNode;
	header?: ReactNode;
	marginBottom?: boolean;
	/**
	 * @deprecated not read anymore
	 */
	withPadding?: boolean;
	noYPadding?: boolean;
	withFormChangeState?: boolean;
	color?: "default" | "error" | "warning" | "success";
};

export function Card(props: CardProps) {
	const {
		wrapperClassName,
		className,
		children,
		marginBottom,
		header,
		noYPadding = false,
		withFormChangeState = false,
		isPreventNavigation = false,
		color = "default",
	} = props;

	usePreventNavigation(isPreventNavigation);

	const formState = useFormChangeState();
	const formStateCss = withFormChangeState
		? {
				"border-yellow-600": formState === FormChangeState.DIRTY,
				"animate-borderGreenToGray": formState === FormChangeState.SAVED,
		  }
		: "border-gray-200";

	return (
		<div
			className={clsx(
				"relative rounded border transition-[border-color] ease-[cubic-bezier(0,0,0.2,1)] duration-1000",
				wrapperClassName,
				{
					"mb-6": marginBottom,
					"border-red-600": color === "error",
					"border-yellow-600": color === "warning" || formState === FormChangeState.DIRTY,
					"border-green-500": color === "success",
				},
				formStateCss,
			)}
		>
			{header && (
				<div
					className={clsx(
						"bg-gray-100 p-2 rounded-t border-b border-gray-200",
						{
							"bg-yellow-500": color === "warning" || formState === FormChangeState.DIRTY,
							"animate-bgGreenToGray": formState === FormChangeState.SAVED,
							"text-white": color === "warning" || formState === FormChangeState.DIRTY,
						},
						{
							"bg-red-500": color === "error",
							"text-white": color === "error",
						},
					)}
				>
					{withFormChangeState && formState === FormChangeState.DIRTY && (
						<div className="float-right flex gap-1" style={{ marginTop: "0.125rem" }}>
							<WarningIcon size="S" className="text-white" />
							<div className="text-xs text-white">Unsaved changes</div>
						</div>
					)}
					{typeof header === "string" ? <div className="font-bold uppercase text-sm">{header}</div> : header}
				</div>
			)}
			<div
				className={clsx(className, "flex flex-col gap-4", {
					"py-4": !noYPadding,
				})}
			>
				{children}
			</div>
		</div>
	);
}

export function BoundaryCard(props: { children: ReactNode }) {
	return (
		<ErrorBoundary
			fallbackRender={({ error }) => (
				<div className="rounded border border-red-300">
					<div className="p-2 rounded-t bg-red-100 border-b border-red-300 font-bold uppercase text-sm">Error</div>
					<pre className="p-2 rounded-b text-xs overflow-y-auto">{error.stack}</pre>
				</div>
			)}
		>
			{props.children}
		</ErrorBoundary>
	);
}

export interface CardHeaderProps {
	title: string | ReactNode;
	actions?: ReactNode;
}

export function CardHeader({ title, actions }: CardHeaderProps) {
	return (
		<div className="flex items-center justify-between">
			<div className="font-bold uppercase text-sm">{title}</div>
			{actions ? <div className="flex gap-2">{actions}</div> : null}
		</div>
	);
}

export const itemPadding = "px-2";
