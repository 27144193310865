import { ReactNode, ReactPortal, useLayoutEffect, useMemo } from "react";
import { createPortal } from "react-dom";

type PortalProps = {
	children: ReactNode;
	type?: string;
};

export function Portal({ children, type = "div" }: PortalProps): ReactPortal {
	const host = useMemo(() => {
		return document.createElement(type);
	}, [type]);

	useLayoutEffect(() => {
		document.body.appendChild(host);
		return () => {
			document.body.removeChild(host);
		};
	}, [host]);

	return createPortal(children, host);
}
