import isEqual from "lodash/isEqual";
import { useFormState } from "react-final-form";

export enum FormChangeState {
	DIRTY,
	SAVED,
	NOT_DIRTY,
}

export function useFormChangeState(): FormChangeState {
	try {
		const formState = useFormState();
		const isDirty = !isEqual(formState.initialValues, formState.values);

		if (isDirty) return FormChangeState.DIRTY;
		if (formState.submitSucceeded) return FormChangeState.SAVED;
	} catch {
		// Ignore the error when the component is not wrapped into a `Form`.
	}
	return FormChangeState.NOT_DIRTY;
}
