import clsx from "clsx";
import type { ReactNode } from "react";

interface AlertProps {
	color: "success" | "info" | "warning" | "error";
	children: ReactNode;
}

export function Alert({ color, children }: AlertProps) {
	return (
		<div
			className={clsx("border border-solid rounded py-3 px-5", {
				"text-emerald-700 bg-emerald-100": color === "success",
				"text-blue-700 bg-blue-100": color === "info",
				"text-amber-700 bg-amber-100": color === "warning",
				"text-red-700 bg-red-100": color === "error",
			})}
		>
			{children}
		</div>
	);
}
