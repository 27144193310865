import clsx from "clsx";
import type { InputHTMLAttributes } from "react";
import { forwardRef } from "react";

export function inputStyle({ readOnly, disabled, warning, invalid }: InputProps) {
	return clsx(
		"py-1 text-sm text-gray-600 transition-colors",
		"border-0 border-b border-solid border-gray-400",
		"focus:outline-none focus:border-blue-700",
		{
			"pointer-events-none": readOnly,
			"cursor-not-allowed": disabled,
			"ring-2 ring-offset-2 ring-opacity-50 rounded border-none": invalid || warning,
			"ring-red-400": invalid,
			"ring-yellow-600": warning,
		},
	);
}

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	invalid?: boolean;
	warning?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(props, ref) {
	const {
		className,
		disabled = false,
		invalid = false,
		warning = false,
		readOnly = false,
		type = "text",
		...rest
	} = props;

	return (
		<input
			{...rest}
			ref={ref}
			type={type}
			className={clsx(className, inputStyle({ invalid, warning, readOnly, disabled }))}
			disabled={disabled}
			readOnly={readOnly}
		/>
	);
});
