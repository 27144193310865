import clsx from "clsx";
import type { ButtonHTMLAttributes, ComponentType } from "react";
import { createElement, useMemo } from "react";

import { FormChangeState, useFormChangeState } from "@hooks/use-form-change-state";

import type { IconPropsWithoutChildren } from "./icon";

export function useButtonStyle({
	disabled,
	loading,
	color,
	active,
	hasIcon = false,
	isIcon = false,
	notRounded,
	isEmphasized = false,
}: Pick<ButtonProps, "disabled" | "loading" | "color" | "notRounded" | "active" | "isEmphasized"> & {
	hasIcon?: boolean;
	isIcon?: boolean;
}) {
	return useMemo(() => {
		const hasOpacity = disabled || loading;
		return [
			"inline-flex items-center justify-center select-none no-underline transition-colors text-sm gap-x-1 relative",
			{
				rounded: !notRounded,
			},
			{
				"bg-gray-200": !color,
				"opacity-50": hasOpacity,
				"cursor-wait": loading,
				"cursor-not-allowed": disabled,
			},
			{
				"h-8 pr-3": !isIcon,
				"pl-3": !(isIcon || hasIcon),
				"pl-2": !isIcon && hasIcon,
				"p-1.5": isIcon,
			},
			{
				"bg-gray-400 text-white": color === "secondary",
				"hover:bg-gray-200": color === "secondary" && !hasOpacity,
			},
			{
				"bg-blue-500 text-white": color === "info",
				"hover:bg-blue-400": color === "info" && !hasOpacity,
			},
			{
				"bg-red-500 text-white": color === "danger",
				"hover:bg-red-400": color === "danger" && !hasOpacity,
			},
			{
				"bg-green-500 text-white": color === "success",
				"hover:bg-green-400": color === "success" && !hasOpacity,
			},
			{
				" bg-amber-100 text-amber-700": color === "warning",
				"hover:bg-amber-300": color === "warning" && !hasOpacity,
			},
			{
				"bg-transparent text-gray-600": color === "transparent",
				"hover:bg-gray-100": color === "transparent" && !active && !hasOpacity,
				"bg-gray-300": color === "transparent" && active && !hasOpacity,
				"!bg-blue-300 text-white": active,
			},
			{
				"before:animate-width before:content-[''] before:rounded before:absolute before:bottom-0 before:left-0 before:w-0 before:h-full before:bg-white before:opacity-10":
					isEmphasized,
			},
		];
	}, [disabled, loading, color, isIcon, hasIcon, notRounded, active, isEmphasized]);
}

export type ButtonColor = "primary" | "secondary" | "info" | "danger" | "transparent" | "success" | "warning";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	loading?: boolean;
	active?: boolean;
	color?: ButtonColor;
	notRounded?: boolean;
	icon?: ComponentType<IconPropsWithoutChildren>;
	isEmphasized?: boolean;
}

export function Button({
	children,
	className,
	active = false,
	disabled = false,
	loading = false,
	color = "info",
	notRounded,
	icon,
	isEmphasized = false,
	...rest
}: ButtonProps) {
	const formState = useFormChangeState();

	const buttonStyle = useButtonStyle({
		color,
		disabled,
		loading,
		notRounded,
		hasIcon: icon !== undefined,
		active,
		isEmphasized: isEmphasized && formState === FormChangeState.DIRTY,
	});

	return (
		<button type="button" className={clsx(className, buttonStyle)} disabled={disabled || loading} {...rest}>
			{icon && createElement(icon)}
			{children}
		</button>
	);
}

/**
 * Icon button is an icon wrapper with defaults to secondary color
 */
export function IconButton({
	className,
	disabled = false,
	loading = false,
	color = "info",
	notRounded,
	active = false,
	...rest
}: ButtonProps) {
	const buttonStyle = useButtonStyle({
		color,
		disabled,
		loading,
		isIcon: true,
		notRounded,
		active,
	});
	const isDisabled = disabled || loading;

	return <button type="button" className={clsx(className, buttonStyle)} disabled={isDisabled} {...rest} />;
}
